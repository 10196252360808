import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

import { initialiseTelInput } from '../../utils/phone-input';

const MuiTelInput = ({
    initialCountry = 'GB',
    preferredCountries = ['GB', 'US', 'NL', 'AU', 'NZ', 'IE', 'FR', 'ES'],
    setIntlTelInput = null,
    name = 'phone',
    isValidCheck = () => {},
    ...props
}) => {
    const randomId = 'input-tel-phone' + Math.random().toString(16).slice(2);
    const [countryCode, setCountryCode] = useState(initialCountry);

    useEffect(() => {
        const phone = document.getElementById(randomId);

        if (phone) {
            try {
                //TODO: Update this library's utils script when it's updated
                const intlPhoneInput = initialiseTelInput({
                    element: phone,
                    initialCountry: countryCode,
                    preferredCountries,
                });

                setIntlTelInput && setIntlTelInput(intlPhoneInput);
            } catch (e) {
                console.error(e);

                const intlPhoneInput = initialiseTelInput({ element: phone, preferredCountries });

                setIntlTelInput && setIntlTelInput(intlPhoneInput);
            }
        }
    }, [countryCode]);

    useEffect(() => {
        let splitCountryCode = window.navigator.language.split('-')[1];

        if (!splitCountryCode) {
            splitCountryCode = window.navigator.language.split('-')[0];
        }

        setCountryCode(splitCountryCode.toUpperCase() || 'GB');
    }, []);

    return (
        <TextField
            fullWidth
            id={randomId}
            name={name}
            // type="tel"
            variant="outlined"
            onChange={isValidCheck}
            onBlur={isValidCheck}
            onClick={isValidCheck}
            {...props}
        />
    );
};

export default MuiTelInput;
