import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

export default function PostHogPageView() {
    const { hash, pathname } = useLocation();
    let [searchParams] = useSearchParams();
    const posthog = usePostHog();

    // Track pageviews
    useEffect(() => {
        try {
            if (hash && posthog) {
                const toolbarJSON = hash.substring(1).get('__posthog');

                if (toolbarJSON) {
                    posthog.loadToolbar(JSON.parse(toolbarJSON));
                }
            }

            if (pathname && posthog) {
                let url = window.origin + pathname;
                if (searchParams.toString()) {
                    url = url + `?${searchParams.toString()}`;
                }
                posthog.capture('$pageview', {
                    $current_url: url,
                });
            }
        } catch (error) {
            console.error('Error tracking pageview', error.message);
        }
    }, [pathname, searchParams, posthog]);

    return null;
}
