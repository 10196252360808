import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.LOADING_REFERENCING_STATS:
                draft.loading = payload.loading;

                break;

            case actions.RECEIVED_REFERENCING_STATS:
                return {
                    ...draft,
                    ...payload,
                };
        }
    });
