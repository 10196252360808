import React from 'react';
import { Link } from 'react-router-dom';
import { injectLocale } from '../../utils/localisation';

import Logo from '../../../assets/refnow-brand-logo.svg';
import Routes from '../../constants/routes';

const Unauthorised = ({ formatMessage }) => (
    <div className="container" id="unauthorised_view">
        <div className="unauthorised-section">
            <div className="text-align" style={{ padding: '25px' }}>
                <Link to={Routes.employer}>
                    <div className="logoroundedback">
                        <Logo alt="RefNow" title="RefNow" height="50" width="160" />
                    </div>
                </Link>
            </div>
            <div className="col-xs-12 col-sm-offset-1 col-sm-10 ">
                <div className="well text-center">
                    <h4 className="unauthorised-heading">
                        {formatMessage('Unauthorised.noAccess')}
                    </h4>
                    <p>{formatMessage('Unauthorised.backToHomepage')}</p>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: '0',
                        paddingBottom: '49%',
                        position: 'relative',
                    }}
                >
                    <iframe
                        src="https://giphy.com/embed/1OHbGj1Rfu2wo"
                        width="100%"
                        height="100%"
                        style={{ position: 'absolute', maxHeight: '280px' }}
                        frameBorder="0"
                        className="giphy-embed"
                        allowFullScreen
                    />
                </div>
                <p>
                    <a href="https://giphy.com/gifs/shawbrothersuniverse-martial-arts-shaw-brothers-shaolin-temple-1OHbGj1Rfu2wo" />
                </p>
            </div>
        </div>
    </div>
);

export default injectLocale(Unauthorised);
