import { produce } from 'immer';
import * as actions from '../constants/action-types';

const MAX_LIST_LENGTH_FROM_SERVER = 99;

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.GET_VACANCY_POOL_LIST:
                draft.loading = true;

                if (payload.start === 0) {
                    draft.list = [];
                    draft.hasMoreSubscribers = false;
                }

                break;

            case actions.RECEIVED_VACANCY_POOL_LIST:
                draft.loading = false;
                draft.hasMoreSubscribers = payload.list.length >= MAX_LIST_LENGTH_FROM_SERVER;

                if (payload.start === 0) {
                    draft.list = payload.list;
                } else {
                    draft.list = draft.list.concat(payload.list);
                }

                break;

            case actions.VACANCY_POOL_LIST_ERROR:
                draft.loading = false;
                draft.errors.message = payload.message;

                break;
        }
    });
