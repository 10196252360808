import * as ActionTypes from '../constants/action-types';

const eventMap = new Map();

eventMap.set(ActionTypes.LOGIN_REQUEST);
eventMap.set(ActionTypes.LOGIN_SUCCESS);
eventMap.set(ActionTypes.LOGIN_ERROR);
eventMap.set(ActionTypes.LOGGED_OUT);
eventMap.set(ActionTypes.NETWORK_ERROR);
eventMap.set(ActionTypes.POST_REFERENCE_REQUEST);
eventMap.set(ActionTypes.REFERENCE_REQUEST_ERROR);
eventMap.set(ActionTypes.REFERENCE_REQUEST_SUCCESS);
eventMap.set(ActionTypes.REGISTER_REQUEST);
eventMap.set(ActionTypes.REGISTRATION_ERROR);
eventMap.set(ActionTypes.REGISTRATION_SUCCESS);

export const analytics = () => (next) => (action) => {
    window.dataLayer = window.dataLayer || [];

    if (action != null && eventMap.has(action.type)) {
        window.dataLayer.push({
            event: action.type,
            payload: action.payload,
        });
    }

    return next(action);
};
