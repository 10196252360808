import VanillaToasts from 'vanillatoasts';
import { getIntl } from '../utils/localisation/IntlWrapper.js';

export const notificationMessage =
    ({
        additionalMsg = '',
        type,
        timeout = 4000,
        titleKey,
        textKey,
        textValues = {},
        message,
        messageArray = [],
    }) =>
    (_, getState) => {
        const intl = getIntl(getState().locales);
        const title = titleKey
            ? intl.formatMessage({ id: titleKey })
            : intl.formatMessage({ id: `Notifications.${type}` });
        let text = '';
        if (!textKey && message) {
            text = message;
        } else if (messageArray.length) {
            text = messageArray.join('\n');
        } else {
            text = `${intl.formatMessage({ id: textKey }, textValues)}${
                additionalMsg && `: ${additionalMsg}`
            }`;
        }

        VanillaToasts.create({
            type,
            timeout,
            title,
            text,
        });
    };

export const noDispatch_notificationMessage = ({
    additionalMsg = '',
    type,
    timeout = 4000,
    titleKey,
    textKey,
    textValues = {},
    message,
    messageArray = [],
    formatMessage,
}) => {
    const title = titleKey
        ? formatMessage({ id: titleKey })
        : formatMessage({ id: `Notifications.${type}` });

    let text = '';
    if (!textKey && message) {
        text = message;
    } else if (messageArray.length) {
        text = messageArray.join('\n');
    } else {
        text = `${formatMessage({ id: textKey }, textValues)}${
            additionalMsg && `: ${additionalMsg}`
        }`;
    }

    VanillaToasts.create({
        type,
        timeout,
        title,
        text,
    });
};
