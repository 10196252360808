import React from 'react';
import { injectIntl } from 'react-intl';

const formatMessage = (formatMessage, id, values) => formatMessage({ id }, values);

const formatCurrency = (formatNumber, value, currency) =>
    formatNumber(value, { style: 'currency', currency });

const formatDate = (
    formatDate,
    date,
    options = { year: 'numeric', month: 'numeric', day: 'numeric' }
) => formatDate(date, options);

export const injectLocale = (Component) => {
    return injectIntl(
        //eslint-disable-next-line
        class extends React.Component {
            constructor() {
                super();

                this.formatMessage = this.formatMessage.bind(this);
                this.formatCurrency = this.formatCurrency.bind(this);
                this.formatDate = this.formatDate.bind(this);
                this.formatTime = this.formatTime.bind(this);
            }

            formatMessage(id, values) {
                return formatMessage(this.props.intl.formatMessage, id, values);
            }

            formatCurrency(value, currency) {
                return formatCurrency(this.props.intl.formatNumber, value, currency);
            }

            formatDate(date, options) {
                return formatDate(this.props.intl.formatDate, date, options);
            }

            formatTime(date, options) {
                return this.props.intl.formatTime(date, options);
            }

            render() {
                return (
                    <Component
                        {...this.props}
                        formatMessage={this.formatMessage}
                        formatCurrency={this.formatCurrency}
                        formatDate={this.formatDate}
                        formatTime={this.formatTime}
                    />
                );
            }
        }
    );
};
