import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useHref, useNavigate } from 'react-router-dom';
import Routes from '../constants/routes.js';
import { getEmployerAccountDetails } from '../actions/userActions.js';
import { setRedirectUrl } from '../actions/loginActions.js';
import { useDidUpdate } from '../utils/customHooks';

const Authorisation = ({ user, getEmployerAccountDetails, setRedirectUrl }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const redirectUrl = useHref({ to: location });

    useEffect(() => {
        getEmployerAccountDetails();
    }, []);

    useDidUpdate(() => {
        const { isLoggedin, fetchedUser } = user;

        if (fetchedUser && isLoggedin === 0) {
            setRedirectUrl(redirectUrl);

            const searchParams = new URLSearchParams(window.location.search);

            return navigate(`${Routes.login}?${searchParams.toString()}`);
        }
    }, [user]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const nextParam = searchParams.get('next');

        if (user.isLoggedin === 1 && nextParam) {
            if (
                nextParam.startsWith('https://api.refnow.co/') ||
                nextParam.startsWith('https://app.refnow.co/')
            ) {
                window.location.assign(nextParam);
            }
        }
    }, [user.isLoggedin]);

    if (user.fetchedUser && user.isLoggedin === 1) {
        return <Outlet />;
    } else {
        return null;
    }
};

const mapStateToProps = ({ user }) => ({
    user,
});

const mapDispatchToProps = {
    getEmployerAccountDetails,
    setRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorisation);
