import React, { useEffect } from 'react';
import { Select, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { setLocale } from '../../actions/localeActions';
import { usePrevious } from '../customHooks';
import { track } from '../analytics';

export const localeOptions = [
    {
        localeKey: 'en-gb',
        localeShort: 'en',
        flag: '🇬🇧',
        name: 'English',
    },
    {
        localeKey: 'es-es',
        localeShort: 'es',
        flag: '🇪🇸',
        name: 'Español',
    },
    {
        localeKey: 'fr-fr',
        localeShort: 'fr',
        flag: '🇫🇷',
        name: 'French',
    },
    {
        localeKey: 'nl-nl',
        localeShort: 'nl',
        flag: '🇳🇱',
        name: 'Dutch',
    },
];

const LocaleSelector = ({ locales, setLocale, reloadPageOnChange = false, ...props }) => {
    const prevLocale = usePrevious(locales.locale);

    const currentLang = window.localStorage.getItem('locale');
    const browserLang = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();

    if (browserLang === 'en-gb') {
    } //do nothing as en-gb is default lang already
    else {
        if (currentLang === null) {
            //check that lang has not already been set manually by user

            //because the browser local could be formatted as 'fr' or 'fr-fr' or 'fr-ca' we need to check all 3 scenarios below

            if (localeOptions.some((el) => el.localeKey === browserLang)) {
                //check if (fr-fr = fr-fr) exists in localeOptions array
                setLocale(browserLang);
            } else if (
                localeOptions.some((el) => el.localeKey === browserLang + '-' + browserLang)
            ) {
                //check if browserlang-browserlang (fr = fr-fr) exists in localeOptions array
                setLocale(browserLang + '-' + browserLang);
            } else if (localeOptions.some((el) => el.localeShort === browserLang.split('-')[0])) {
                //check if (fr = fr-ca) exists in localeOptions array
                setLocale(
                    localeOptions.find((element) => {
                        return element.localeShort === browserLang.split('-')[0];
                    }).localeKey
                );
            }
        }
    }

    useEffect(() => {
        if (reloadPageOnChange && prevLocale && prevLocale !== locales.locale) {
            window.location.assign(window.location.href);
        }
    }, [locales.locale]);

    return (
        <Select
            id="navigation-locale-selector"
            value={locales.locale}
            onChange={({ target }) => {
                track('Set locale', {
                    locale: target.value,
                });
                setLocale(target.value);
            }}
            {...props}
            sx={{ fontWeight: 400, ...props.sx }}
        >
            {localeOptions.map((opt) => (
                <MenuItem key={opt.localeKey} value={opt.localeKey}>
                    {opt.flag}&nbsp;&nbsp;{opt.name}
                </MenuItem>
            ))}
        </Select>
    );
};

const mapStateToProps = ({ locales }) => ({ locales });

const mapDispatchToProps = { setLocale };

export default connect(mapStateToProps, mapDispatchToProps)(LocaleSelector);
