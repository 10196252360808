import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    API_URL,
    GET_CANDIDATE_FORM_DETAILS_URL,
    POST_CANDIDATE_DRAFT_DATA,
    POST_NEW_REFERENCE,
    POST_REFEREE_VERIFY_DETAILS,
} from '../../../../constants/api';

import { notificationMessage } from '../../../../actions/notificationMessageActions';

export const REFERENCE_TYPES = Object.freeze({
    PERSONAL: 'personal',
    PROFESSIONAL: 'professional',
    EDUCATIONAL: 'educational',
    EMPLOYMENT_GAP: 'gap',
});

export const CANDIDATE_STEPS = Object.freeze({
    WELCOME: 1,
    DASHBOARD: 2,
    REFERENCE: 3,
    REFERENCE_DETAILS_SUBMITTED: 4,
    EMPLOYMENT_GAP: 5,
});

export const REFERENCE_STEPS = Object.freeze({
    INTRODUCTION: 1,
    RELATIONSHIP: 2,
    REFEREE_DETAILS: 3,
    CANDIDATE_DETAILS: 4,
    SUMMARY: 5,
});

export const EMPLOYMENT_GAP_STEPS = Object.freeze({
    INTRODUCTION: 1,
    GAP_DETAILS: 2,
    UPLOAD_DOCUMENTS: 3,
    SUMMARY: 4,
});

const initialSteps = [
    REFERENCE_STEPS.RELATIONSHIP,
    REFERENCE_STEPS.REFEREE_DETAILS,
    REFERENCE_STEPS.CANDIDATE_DETAILS,
    REFERENCE_STEPS.SUMMARY,
];

const initialState = {
    loading: false,
    saving: false,
    submitting: false,
    token: '',
    error: {},
    details: {},
    filesUploaded: [],
    draft_data: {
        currentStep: 1,
        currentReference: 0,
        references: [],
    },
};

//TODO: progress step needs to be set to 1 when form goes live. This is for testing purposes
const initialDraftReference = {
    currentProgressStep: 1,
    steps: initialSteps,
    history: [1],
    type: '',
    referee_fullname: '',
    referee_email: '',
    referee_phone: '',
    referee_relationship: '',
    referee_company: '',
    referee_known_since: '',
    job_title: '',
    school: '',
    course_name: '',
    start_date: '',
    end_date: '',
    gap_upload_id: '',
};

const candidateOnboardingSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        setCurrentStep: (state, action) => {
            state.draft_data.currentStep = action.payload;
        },
        setSteps: (state, action) => {
            if (action.payload === REFERENCE_TYPES.PERSONAL) {
                state.draft_data.references[state.draft_data.currentReference].steps = [
                    REFERENCE_STEPS.RELATIONSHIP,
                    REFERENCE_STEPS.REFEREE_DETAILS,
                    REFERENCE_STEPS.SUMMARY,
                ];
            } else if (action.payload === REFERENCE_TYPES.EMPLOYMENT_GAP) {
                state.draft_data.references[state.draft_data.currentReference].steps = [
                    EMPLOYMENT_GAP_STEPS.GAP_DETAILS,
                    EMPLOYMENT_GAP_STEPS.UPLOAD_DOCUMENTS,
                    EMPLOYMENT_GAP_STEPS.SUMMARY,
                ];
            } else {
                state.draft_data.references[state.draft_data.currentReference].steps = initialSteps;
            }
        },
        previousReferenceStep: (state) => {
            const currentReference = state.draft_data.currentReference;

            if (state.draft_data.references[currentReference].history.length >= 1) {
                const previousStep =
                    state.draft_data.references[currentReference].history.length - 1;

                state.draft_data.references[currentReference].currentProgressStep =
                    state.draft_data.references[currentReference].history[previousStep];
                state.draft_data.references[currentReference].history.pop();
            }
        },
        nextReferenceStep: (state, action) => {
            const currentReference = state.draft_data.currentReference;
            const nextStep =
                action.payload ||
                state.draft_data.references[currentReference].currentProgressStep + 1;

            state.draft_data.references[currentReference].history.push(
                state.draft_data.references[currentReference].currentProgressStep
            );
            state.draft_data.references[currentReference].currentProgressStep = nextStep;
        },
        goToReferenceStep: (state, action) => {
            const currentReference = state.draft_data.currentReference;

            state.draft_data.references[currentReference].history.push(
                state.draft_data.references[currentReference].currentProgressStep
            );

            state.draft_data.references[currentReference].currentProgressStep = action.payload;
        },
        setCandidateToken: (state, action) => {
            state.token = action.payload;
        },
        createDraftReference: (state, action) => {
            state.draft_data.references.push({
                ...initialDraftReference,
                type: action?.payload?.type || '',
            });
            state.draft_data.currentReference = state.draft_data.references.length - 1;
        },
        updateActiveReference: (state, action) => {
            state.draft_data.currentReference = action.payload;
        },
        saveDraftReference: (state, action) => {
            if (action.payload.type) {
                state.draft_data.references[state.draft_data.currentReference].type =
                    action.payload.type;
            }

            state.draft_data.references[state.draft_data.currentReference] = {
                ...state.draft_data.references[state.draft_data.currentReference],
                ...action.payload.data,
            };
        },
        setDraftReferences: (state, action) => {
            state.draft_data.references = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        saveFileUpload: (state, action) => {
            state.filesUploaded.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCandidateDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCandidateDetails.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCandidateDetails.fulfilled, (state, action) => {
            const { draft_data, employmentGapsAllowed, ...details } = action.payload;

            state.loading = false;
            state.details = details;
            state.employmentGapsAllowed = employmentGapsAllowed;
            state.draft_data = {
                ...state.draft_data,
                ...draft_data,
            };
        });
        builder.addCase(saveCandidateDraftData.pending, (state) => {
            state.saving = true;
        });
        builder.addCase(saveCandidateDraftData.rejected, (state) => {
            state.saving = false;
        });
        builder.addCase(saveCandidateDraftData.fulfilled, (state) => {
            state.saving = false;
        });
        builder.addCase(verifyContactDetails.pending, (state) => {
            state.saving = true;
        });
        builder.addCase(verifyContactDetails.rejected, (state) => {
            state.saving = false;
        });
        builder.addCase(verifyContactDetails.fulfilled, (state) => {
            state.saving = false;
        });
        builder.addCase(updateDraftReferenceDetails.pending, (state) => {
            state.detailsUpdated = false;
        });
        builder.addCase(updateDraftReferenceDetails.fulfilled, (state) => {
            state.detailsUpdated = true;
        });
        builder.addCase(submitReference.pending, (state) => {
            state.submitting = true;
            state.error = {};
        });
        builder.addCase(submitReference.rejected, (state) => {
            state.submitting = false;
        });
        builder.addCase(submitReference.fulfilled, (state) => {
            state.submitting = false;
        });
    },
});

// get candidate details from the basic endpoint
export const getCandidateDetails = createAsyncThunk(
    'candidate/getCandidateDetails',
    async (data, thunkAPI) => {
        const { token, overwriteCurrentStep = 0 } = data;

        try {
            const url = `${API_URL}${GET_CANDIDATE_FORM_DETAILS_URL}`;
            const config = {
                params: {
                    token: token || thunkAPI.getState().forms.candidate.token,
                },
                withCredentials: true,
            };

            const response = await axios.get(url, config);
            const responseData = response?.data?.data[0] || {};
            const draft_data = responseData?.draft_data || initialState.draft_data;
            let currentStep =
                draft_data?.currentStep >= CANDIDATE_STEPS.DASHBOARD
                    ? CANDIDATE_STEPS.DASHBOARD
                    : CANDIDATE_STEPS.WELCOME;

            if (overwriteCurrentStep) {
                currentStep = overwriteCurrentStep;
            }

            const { refereesadded, refnum, required_range_months } = responseData;
            const placeholderRefs = refnum - refereesadded - draft_data?.references.length;
            const placeholderReferences = Array.from({ length: placeholderRefs }).map(() => ({
                ...initialDraftReference,
            }));
            const employmentGapsAllowed = required_range_months > 0;

            return {
                ...responseData,
                employmentGapsAllowed,
                draft_data: {
                    ...draft_data,
                    currentStep,
                    references: [...draft_data.references, ...placeholderReferences],
                },
            };
        } catch (error) {
            console.error('Error getting candidate details:', error);
            return {};
        }
    }
);

// save the entire candidate draft data
export const saveCandidateDraftData = createAsyncThunk(
    'candidate/saveCandidateDraftData',
    async (draftData, thunkAPI) => {
        try {
            const url = `${API_URL}${POST_CANDIDATE_DRAFT_DATA}`;
            const params = new URLSearchParams();
            params.append('inputtoken', thunkAPI.getState().forms.candidate.token);
            params.append(
                'inputdraftdata',
                JSON.stringify(draftData || thunkAPI.getState().forms.candidate.draft_data)
            );

            const config = {
                withCredentials: true,
            };

            const response = await axios.post(url, params, config);

            return response?.data?.data || {};
        } catch (error) {
            console.error('Error posting candidate draft data:', error);
            return {};
        }
    }
);

// on success proceed to the next step
export const saveDraftReferenceData = createAsyncThunk(
    'candidate/saveDraftReferenceData',
    async ({ type = '', data, nextStep }, thunkAPI) => {
        try {
            await thunkAPI.dispatch(saveDraftReference({ data, type }));
            const draftDataAction = await thunkAPI.dispatch(saveCandidateDraftData());

            if (draftDataAction.meta.requestStatus === 'fulfilled') {
                thunkAPI.dispatch(nextReferenceStep(nextStep));
            }
        } catch (error) {
            console.error('Error saving draft reference data:', error);
        }
    }
);

// same as the action above but without proceeding to the next step
// TODO: refactor to use the action above
export const updateDraftReferenceDetails = createAsyncThunk(
    'candidate/updateDraftReferenceDetails',
    async ({ data }, thunkAPI) => {
        try {
            await thunkAPI.dispatch(saveDraftReference({ data }));
            await thunkAPI.dispatch(saveCandidateDraftData());
        } catch (error) {
            console.error('Error saving draft reference data:', error);
        }
    }
);

// handle date input, especially present dates
const handleDate = (dateInput) => {
    if (!dateInput || dateInput === '0000-00-00') {
        return {
            getDate: () => '0',
            getMonth: () => '0',
            getFullYear: () => '0000',
        };
    } else {
        const date = new Date(dateInput);
        return {
            getDate: () => date.getDate(),
            getMonth: () => parseInt(date.getMonth() + 1),
            getFullYear: () => date.getFullYear(),
        };
    }
};

// utility function to append params to the form data or not if the value is empty
const checkAndAppendParam = (params, key, value) => {
    if (value) {
        params.append(key, value);
    }
};

// submit the reference form
export const submitReference = createAsyncThunk(
    'candidate/submitReference',
    async (data, thunkAPI) => {
        try {
            const url = `${API_URL}${POST_NEW_REFERENCE}`;
            const params = new URLSearchParams();
            const draftData = thunkAPI.getState().forms.candidate.draft_data;
            const { currentReference, references } = draftData;
            const reference = references[currentReference];
            const startDate =
                reference.type === REFERENCE_TYPES.PERSONAL
                    ? handleDate(reference.referee_known_since)
                    : handleDate(reference.start_date);
            const endDate = handleDate(reference.end_date);

            //other params
            params.append('inputtoken', thunkAPI.getState().forms.candidate.token);
            params.append('inputemail_validation_off', reference.email_validation_off ? 1 : 0);
            params.append('referer', location.href);
            params.append('inputrelationship', reference.referee_relationship);
            params.append('inputphone', reference.phoneNumber);
            params.append('inputphonetype', reference.phoneNumberType);

            checkAndAppendParam(params, 'inputfullname', reference.referee_fullname);
            checkAndAppendParam(params, 'inputcompany', reference.referee_company);
            checkAndAppendParam(params, 'inputemail', reference.referee_email);
            checkAndAppendParam(params, 'inputschool', reference.school);
            checkAndAppendParam(params, 'inputcourse', reference.course_name);
            checkAndAppendParam(params, 'inputjobtitle', reference.job_title);
            checkAndAppendParam(params, 'inputstartday', startDate.getDate());
            checkAndAppendParam(params, 'inputstartmonth', startDate.getMonth());
            checkAndAppendParam(params, 'inputstartyear', startDate.getFullYear());
            checkAndAppendParam(params, 'inputendday', endDate.getDate());
            checkAndAppendParam(params, 'inputendmonth', endDate.getMonth());
            checkAndAppendParam(params, 'inputendyear', endDate.getFullYear());

            const config = {
                withCredentials: true,
            };

            const response = await axios.post(url, params, config);

            if (response.data.status === 'error') {
                thunkAPI.dispatch(
                    notificationMessage({
                        message:
                            'An error occurred. Please scroll to the bottom of the page to see the errors',
                        type: 'error',
                    })
                );

                return thunkAPI.dispatch(
                    setError({
                        inputs: response.data.data,
                    })
                );
            }

            if (response.data.status === 'success') {
                // Delete the reference from the saved draft data
                const updatedReferences = references.filter(
                    (_, index) => index !== currentReference
                );

                const updatedDraftData = {
                    ...draftData,
                    references: updatedReferences,
                };

                await thunkAPI.dispatch(
                    setCurrentStep(CANDIDATE_STEPS.REFERENCE_DETAILS_SUBMITTED)
                );
                await thunkAPI.dispatch(saveCandidateDraftData(updatedDraftData));
                await thunkAPI.dispatch(
                    getCandidateDetails({
                        overwriteCurrentStep: CANDIDATE_STEPS.REFERENCE_DETAILS_SUBMITTED,
                    })
                );
            }

            return { success: response.data.status === 'success', data: response.data.data };
        } catch (error) {
            if (error.response.status === 500) {
                console.error('Error submitting reference: internal server error 500', error);
                return thunkAPI.dispatch(
                    notificationMessage({
                        message: 'An error occurred. Please try again later.',
                        type: 'error',
                    })
                );
            } else {
                console.error('Error submitting reference:', error);
            }
        }
    }
);

// verify the contact details of the referee i.e. is the email valid and are personal email addresses allowed?
export const verifyContactDetails = createAsyncThunk(
    'candidate/verifyContactDetails',
    async (data, thunkAPI) => {
        try {
            const url = `${API_URL}${POST_REFEREE_VERIFY_DETAILS}`;
            const params = new URLSearchParams();

            const { token, draft_data } = thunkAPI.getState().forms.candidate;
            const { currentReference, references } = draft_data;
            const { referee_relationship } = references[currentReference];
            const { email = '', validationOff = 0 } = data;

            params.append('inputtoken', token);
            params.append('inputrelationship', referee_relationship);
            params.append('inputemail', email);
            params.append('inputemail_validation_off', validationOff ? 1 : 0);

            const config = {
                withCredentials: true,
            };

            const response = await axios.post(url, params, config);

            return response?.data?.data || {};
        } catch (error) {
            console.error('Error posting candidate draft data:', error);
            return {};
        }
    }
);

export const {
    setCurrentStep,
    setCandidateToken,
    createDraftReference,
    updateActiveReference,
    saveDraftReference,
    previousReferenceStep,
    nextReferenceStep,
    goToReferenceStep,
    setSteps,
    setError,
    setDraftReferences,
    saveFileUpload,
} = candidateOnboardingSlice.actions;

export default candidateOnboardingSlice.reducer;
