import {
    NETWORK_ERROR,
    SET_DEVICE_TYPE,
    SET_ONLINE_STATUS,
    WINDOW_RESIZE,
} from '../constants/action-types';

export default function(state = {}, action) {
    switch (action.type) {
        case WINDOW_RESIZE:
            return {
                ...state,
                deviceWidth: action.payload.innerWidth,
                deviceHeight: action.payload.innerHeight,
                isMobileView: action.payload.isMobileView,
            };

        case SET_DEVICE_TYPE:
            return {
                ...state,
                isMobileDevice: action.payload.isMobileDevice,
            };

        case NETWORK_ERROR:
            return {
                ...state,
                error: action.payload.error,
            };

        case SET_ONLINE_STATUS:
            return {
                ...state,
                isOnline: action.payload.isOnline,
            };

        default:
            return state;
    }
}
