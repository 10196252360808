import intlTelInput from 'intl-tel-input';

export function initialiseTelInput({
    element,
    initialCountry = 'GB',
    preferredCountries = ['GB', 'US', 'NL', 'AU', 'NZ', 'IE', 'FR', 'ES'],
}) {
    return intlTelInput(element, {
        initialCountry,
        preferredCountries,
        autoPlaceholder: 'aggressive',
    });
}
