import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.LOGIN_REQUEST:
                draft.isLoggingIn = true;
                draft.isLoggedin = false;
                draft.errorMessage = '';

                break;

            case actions.LOGIN_SUCCESS:
                draft.isLoggingIn = false;
                draft.isLoggedin = true;

                break;

            case actions.LOGIN_ERROR:
                draft.isLoggingIn = false;
                draft.isLoggedin = false;
                draft.errorMessage = payload.errorMessage;

                break;

            case actions.ACTIVATING_EMAIL:
                draft.isActivatingEmail = true;

                break;

            case actions.ACTIVATION_SUCCESS:
                draft.emailActivated = true;
                draft.isActivatingEmail = false;
                draft.activationMessage = payload.activationMessage;
                draft.activationStatus = 'success';

                break;

            case actions.ACTIVATION_ERROR:
                draft.emailActivated = false;
                draft.isActivatingEmail = false;
                draft.activationMessage = payload.activationMessage;
                draft.activationStatus = 'error';

                break;

            case actions.SET_REDIRECT_URL:
                draft.redirectUrl = payload.redirectUrl;

                break;
        }
    });
