import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.LOADING_SETTINGS:
                draft.loading = payload.loading;

                break;

            case actions.RECEIVED_SETTINGS:
                return {
                    ...draft,
                    ...payload,
                };

            case actions.UPDATE_SETTINGS:
                draft.updating = payload.updating;

                break;
        }
    });
