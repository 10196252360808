import * as actions from '../constants/action-types';

export default function (state = {}, { type, payload }) {
    switch (type) {
        case actions.UPDATE_USER_INFO:
            return {
                ...state,
                email: payload.email,
            };
        case actions.UPDATE_ORGANISATION_NAME:
            return {
                ...state,
                organisation: payload.organisation,
                isUpdating: payload.isUpdating,
                message: payload.message,
            };
        case actions.UPDATING_ORGANISATION_NAME:
            return {
                ...state,
                message: '',
                isUpdating: payload.isUpdating,
                error: payload.error,
            };

        case actions.CLEAR_ORG_NAME_MESSAGE:
            return {
                ...state,
                message: payload.message,
            };

        case actions.GET_ACCOUNT_DETAILS:
            return {
                ...state,
                isFetchingUser: payload.isFetchingUser,
                fetchedUser: payload.fetchedUser,
            };
        case actions.UPDATE_ACCOUNT_DETAILS:
            return {
                ...state,
                ...payload,
            };

        case actions.UPDATE_LOGGED_IN_STATUS:
            return {
                ...state,
                isLoggedin: payload.isLoggedin,
                error: payload.error,
            };

        case actions.UPDATE_CREDIT_BALANCE:
            return {
                ...state,
                creditBal: payload.creditBal,
                paidRTWCreditBal: payload.paidRTWCreditBal,
            };

        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutMessage: payload.logoutMessage,
                isLoggedin: payload.loggedIn,
                email: payload.email,
                organisation: payload.organisation,
            };

        case actions.UPDATE_TOUR_STATUS:
            return {
                ...state,
                tours: payload.tours,
            };

        case actions.ERROR_ADMIN_USERS:
        case actions.ERROR_TEAMS:
            return {
                ...state,
                isLoggedin: payload.isLoggedin,
            };

        default:
            return state;
    }
}
