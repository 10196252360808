/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import * as Styled from './styled';
import Routes from '../constants/routes';
import Shape from '../../assets/rect-shape.svg';
import Logo from '../../assets/refnow-logo-white.svg';
import LocaleSelector from '../utils/localisation/locale-selector';
import { useMediaQuery, useTheme } from '@mui/material';
import { getEmployerAccountDetails } from '../actions/userActions';

const LoginRegisterContainer = ({ isLoggedIn, fetchedUser, getEmployerAccountDetails }) => {
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    useEffect(() => {
        getEmployerAccountDetails();
    }, []);

    useEffect(() => {
        //http://localhost:8080/login?next=https://api.refnow.co/v1/reporting/html-reports/candidates/fraud

        if (isLoggedIn) {
            navigate(`${Routes.employer}${window.location.search}`);
        }
    }, [isLoggedIn]);

    if (!fetchedUser) {
        return null;
    }

    return (
        <Styled.Container id="login_container" className="animated fadeInDown">
            <Styled.SplashScreenPanel>
                <div>
                    <Logo onClick={() => window.location.assign(location.origin + '/login')} />
                    <h1>
                        {formatMessage(
                            {
                                id: 'Login.description',
                            },
                            {
                                span: (text) => <span key="description">{text}</span>,
                            }
                        )}
                    </h1>
                    <Styled.ShapeContainer>
                        <Shape />
                    </Styled.ShapeContainer>
                </div>
                <Styled.FooterTerms>
                    {formatMessage(
                        {
                            id: 'Login.establishedDate',
                        },
                        {
                            presentYear: new Date().getFullYear(),
                        }
                    )}
                    &nbsp;
                    <a
                        href="https://refnow.com/terms?utm_source=appfooter"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {formatMessage({ id: 'Login.websiteTerms' })}
                    </a>
                    <span>&nbsp;|&nbsp;</span>
                    <a
                        href="https://refnow.com/privacy?utm_source=appfooter"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {formatMessage({ id: 'Login.websitePrivacyPolicy' })}
                    </a>
                </Styled.FooterTerms>
            </Styled.SplashScreenPanel>
            <Styled.FormPanel>
                <Styled.LocaleSelectorContainer isMobile={isMobile}>
                    <LocaleSelector size="small" />
                </Styled.LocaleSelectorContainer>
                <Styled.OutletContainer isMobile={isMobile}>
                    <Outlet />
                </Styled.OutletContainer>
            </Styled.FormPanel>
        </Styled.Container>
    );
};

const mapStateToProps = ({ user }) => ({
    isLoggedIn: user.isLoggedin,
    fetchedUser: user.fetchedUser,
});

const mapDispatchToProps = {
    getEmployerAccountDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegisterContainer);
