import enGB from '../../translations/en-GB.json';
import esES from '../../translations/es-ES.json';
import frFR from '../../translations/fr-FR.json';
import nlNL from '../../translations/nl-NL.json';

export default {
    'en-gb': enGB,
    'es-es': esES,
    'fr-fr': frFR,
    'nl-nl': nlNL,
};
