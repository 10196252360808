import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
`;

const smallSpinner = css`
    height: 1.5em;
    width: 1.5em;

    > div {
        border-width: 3px;
        margin: 2px;
    }
`;

const mediumSpinner = css`
    height: 3.5em;
    width: 3.5em;

    > div {
        border-width: 6px;
    }
`;

const StyledSpinner = styled.div`
    display: inline-block;
    position: relative;
    height: 5em;
    width: 5em;

    > div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 80%;
        height: 80%;
        margin: 8px;
        border: 8px solid ${({ theme }) => theme.palette.primary.main};
        border-radius: 50%;
        animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${({ theme }) => theme.palette.primary.main} transparent transparent
            transparent;

        :nth-child(1) {
            animation-delay: -0.45s;
        }

        :nth-child(2) {
            animation-delay: -0.3s;
        }

        :nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    ${({ size }) => {
        switch (size) {
            case 'SM':
                return `${smallSpinner}`;

            case 'MD':
                return `${mediumSpinner}`;

            default:
                return '';
        }
    }}
`;

const Spinner = ({ size, color }) => (
    <StyledSpinner size={size} color={color}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </StyledSpinner>
);

const StyledWrapper = styled.div`
    display: flex;
    align-items: ${(props) => (props.position === 'top' ? 'baseline' : 'center')};
    justify-content: center;
    height: 100%;
    min-width: 15em;
`;

export const SpinnerWithWrapper = (props) => (
    <StyledWrapper position={props.position}>
        <Spinner {...props} />
    </StyledWrapper>
);

export default Spinner;
