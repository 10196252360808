import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.GET_ADMIN_USERS:
                draft.loadingUsers = true;

                break;

            case actions.RECEIVED_ADMIN_USERS:
                draft.loadingUsers = false;
                draft.users = payload.users.reduce((acc, { ouid, ...rest }) => {
                    return [
                        ...acc,
                        {
                            id: ouid,
                            ...rest,
                        },
                    ];
                }, []);

                break;

            case actions.ERROR_ADMIN_USERS:
                draft.loadingUsers = false;
                draft.errorMessage = payload.errorMessage;

                break;

            case actions.GET_TEAMS:
                draft.loadingTeams = true;

                break;

            case actions.RECEIVED_TEAMS:
                draft.loadingTeams = false;
                draft.teams = payload.teams.reduce((acc, { teamid, ...rest }) => {
                    return [
                        ...acc,
                        {
                            id: teamid,
                            ...rest,
                        },
                    ];
                }, []);

                break;

            case actions.ERROR_TEAMS:
                draft.loadingTeams = false;

                break;

            case actions.CREATE_NEW_USER:
            case actions.UPDATE_USER:
                draft.savingUser = true;
                draft.savedUser = false;
                draft.savedTeam = false;

                break;

            case actions.CREATED_NEW_USER:
            case actions.UPDATED_USER:
                draft.savingUser = false;
                draft.savedUser = true;

                break;

            case actions.ERROR_CREATING_NEW_USER:
            case actions.ERROR_UPDATING_USER:
                draft.savingUser = false;
                draft.savedUser = false;

                break;

            case actions.CREATE_NEW_TEAM:
            case actions.UPDATE_TEAM:
                draft.savingTeam = true;
                draft.savedUser = false;
                draft.savedTeam = false;

                break;

            case actions.CREATED_NEW_TEAM:
            case actions.UPDATED_TEAM:
                draft.savingTeam = false;
                draft.savedTeam = true;

                break;

            case actions.ERROR_CREATING_NEW_TEAM:
            case actions.ERROR_UPDATING_TEAM:
                draft.savingTeam = false;
                draft.savedTeam = false;

                break;

            case actions.DELETE_USER:
                draft.deletingUser = true;

                break;

            case actions.DELETED_USER:
            case actions.ERROR_DELETING_USER:
                draft.deletingUser = false;

                break;

            case actions.DELETE_TEAM:
                draft.deletingTeam = true;

                break;

            case actions.DELETED_TEAM:
            case actions.ERROR_DELETING_TEAM:
                draft.deletingTeam = false;

                break;
        }
    });
