import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.POST_REFERENCE_REQUEST:
                draft.isPostingRequest = true;
                draft.requestCreated = false;

                break;

            case actions.REFERENCE_REQUEST_SUCCESS:
                draft.isPostingRequest = false;
                draft.requestCreated = true;
                draft.message = payload.message;
                draft.status = payload.status;
                draft.requestUrl = payload.requestUrl;
                draft.requestId = payload.requestId;

                break;

            case actions.REFERENCE_REQUEST_ERROR:
                draft.isPostingRequest = false;
                draft.requestCreated = false;

                break;

            case actions.CLEAR_REFERENCE_REQUEST:
                draft.isPostingRequest = false;
                draft.requestCreated = false;
                draft.message = '';
                draft.status = '';

                break;

            case actions.GET_PROFILE_LIST:
                draft.isFetchingProfileList = true;

                break;

            case actions.RECEIVED_PROFILE_LIST:
                draft.isFetchingProfileList = false;
                draft.questionProfileList = payload.questionProfileList;

                break;

            case actions.ERROR_RECEIVING_PROFILE_LIST:
                draft.isFetchingProfileList = false;
                draft.questionProfileList = [];
                draft.questionProfileListError = payload.questionProfileListError;

                break;

            case actions.GET_QUESTION_PROFILE_PREVIEW:
                draft.questionPreview = {
                    ...draft.questionPreview,
                    isFetchingProfile: true,
                    profile: {},
                };

                break;

            case actions.RECEIVED_QUESTION_PROFILE_PREVIEW:
                draft.questionPreview = {
                    ...draft.questionPreview,
                    isFetchingProfile: false,
                    profile: payload.profile,
                };

                break;

            case actions.ERROR_QUESTION_PROFILE_PREVIEW:
                draft.questionPreview = {
                    ...draft.questionPreview,
                    isFetchingProfile: false,
                    profileErrorMessage: payload.profileErrorMessage,
                };

                break;
        }
    });
