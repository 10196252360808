import React from 'react';
import { useIntl } from 'react-intl';
import { Menu, MenuItem } from '@mui/material';

function NavigationMenu({ anchorEl, setAnchorEl, handleClose, menuId, menuButtonId, menuItems }) {
    const { formatMessage } = useIntl();

    const onClose = () => handleClose(setAnchorEl);

    return (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': menuButtonId,
            }}
        >
            {menuItems.map(
                (item) =>
                    (item.condition === undefined || item.condition) && (
                        <MenuItem
                            key={item.id}
                            id={item.id}
                            onClick={() => {
                                item.onClick();
                                onClose();
                            }}
                            sx={{ px: 3, py: 2 }}
                        >
                            {formatMessage({ id: item.labelId })}
                        </MenuItem>
                    )
            )}
        </Menu>
    );
}

export default NavigationMenu;
