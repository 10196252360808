import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import {
    CLEAR_ORG_NAME_MESSAGE,
    GET_ACCOUNT_DETAILS,
    LOGGED_OUT,
    LOGOUT_SUCCESS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_CREDIT_BALANCE,
    UPDATE_ORGANISATION_NAME,
    UPDATE_TOUR_STATUS,
    UPDATE_USER_INFO,
    UPDATING_ORGANISATION_NAME,
} from '../constants/action-types';

import { updateLoggedInStatus } from '../actions/loginActions';
import { setNetworkError } from '../actions/siteActions';
import {
    API_URL,
    GET_EMPLOYER_ACCOUNT,
    POST_LOGOUT_URL,
    POST_ORGANISATION_NAME_URL,
} from '../constants/api.js';
import Routes from '../constants/routes';
import { track } from '../utils/analytics';

export const updateUserInfo = (email) => {
    return {
        type: UPDATE_USER_INFO,
        payload: {
            email: email,
        },
    };
};

export const editOrganisationName = (organisation) => (dispatch) => {
    const url = API_URL + POST_ORGANISATION_NAME_URL;
    const params = new URLSearchParams();
    const config = { withCredentials: true };

    params.append('inputname', organisation);

    dispatch(updatingOrganisationName(true));

    axios
        .post(url, params, config)
        .then((response) => {
            const responseData = response.data;
            dispatch(updateLoggedInStatus(responseData));

            if (responseData.status === 'error') {
                const errorMessage = responseData.data.input_companyname;

                dispatch(updatingOrganisationName(false, errorMessage));
            }
            if (responseData.status === 'success') {
                dispatch({
                    type: UPDATE_ORGANISATION_NAME,
                    payload: {
                        organisation: organisation,
                        isUpdating: false,
                        message: response.data.message,
                    },
                });
            }
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

const updatingOrganisationName = (isUpdating, error = '') => {
    return {
        type: UPDATING_ORGANISATION_NAME,
        payload: {
            isUpdating,
            error,
        },
    };
};

export const clearOrgNameMessage = () => {
    return {
        type: CLEAR_ORG_NAME_MESSAGE,
        payload: {
            message: null,
        },
    };
};

export const getEmployerAccountDetails = () => {
    return (dispatch) => {
        const url = API_URL + GET_EMPLOYER_ACCOUNT;
        const config = { withCredentials: true };

        dispatch({
            type: GET_ACCOUNT_DETAILS,
            payload: {
                isFetchingUser: true,
                fetchedUser: false,
            },
        });

        axios
            .get(url, config)
            .then((response) => {
                dispatch(updateAccountDetails(response));
            })
            .catch((error) => {
                dispatch(setNetworkError(error));
            });
    };
};

const updateAccountDetails = ({ data }) => {
    const dataArray = data.data[0] || {};
    const roleid = dataArray.roleid;
    const orgid = dataArray.orgid;
    const { reminder1, reminder2, reminder3, reminder4 } = dataArray;
    const isAdmin = roleid === 2;
    const isSuperAdmin = roleid === 3;

    window.dataLayer.push({
        event: 'userInfo',
        email: dataArray.email || '',
        organisation: dataArray.company || '',
        roleid: roleid || '',
        orgid: orgid || '',
        fullname: dataArray.fullname || '',
        phone: dataArray.phone || '',
        createdts: dataArray.createdts || '',
        ouid: dataArray.ouid || '',
        totalcreditbal: dataArray.totalcreditbal || '',
        intercomHash: dataArray.intercomHash || '',
    });

    if (data.loggedin === 1) {
        //eslint-disable-next-line
        if (_hsq && isSuperAdmin) {
            const splitName = dataArray.fullname.split(' ');
            const firstName = splitName[0] || '';
            const lastName = splitName[1] || '';

            //eslint-disable-next-line
            _hsq.push([
                'identify',
                {
                    email: dataArray.email,
                    firstname: firstName,
                    lastname: lastName,
                    company: dataArray.company,
                    phone: dataArray.phone,
                    org_id: dataArray.orgid,
                    org_user_id: dataArray.ouid,
                    app_last_active_date: Date.now(),
                    app_signup_date: dataArray.createdts * 1000,
                },
            ]);

            //eslint-disable-next-line
            _hsq.push(['trackPageView']);
        }

        mixpanel.identify(dataArray.email);
        mixpanel.people.set({
            $email: dataArray.email,
            $name: dataArray.fullname,
            creditBalance: dataArray.totalcreditbal,
            digitalIdCheckCredits: dataArray.paid_rtw_check_bal,
            company: dataArray.company,
            team: dataArray.teamname,
            orgid,
            isAdmin,
            isSuperAdmin,
        });

        posthog.identify(dataArray.ouid, {
            email: dataArray.email,
            name: dataArray.fullname,
            company: dataArray.company,
            team: dataArray.teamname,
            orgid,
            isAdmin,
            isSuperAdmin,
        });

        posthog.group('company', orgid, {
            name: dataArray.company,
            overdue_invoice_days: dataArray.most_overdue_invoice_days,
            date_joined: new Date(dataArray.createdts * 1000).toLocaleDateString(),
            credit_balance: dataArray.totalcreditbal,
            digital_id_credits: dataArray.paid_rtw_check_bal,
        });

        const userType = isAdmin ? 'admin' : isSuperAdmin ? 'superadmin' : 'user';

        if (window.Featurebase) {
            window.Featurebase(
                'identify',
                {
                    // Each 'identify' call should include an "organization" property,
                    // which is your Featurebase board's name before the ".featurebase.app".
                    organization: 'refnow',

                    // Required fields. Replace with your customers data.
                    email: dataArray.email,
                    name: dataArray.fullname,
                    id: `${dataArray.ouid}`,

                    // Optional - include other fields as needed
                    customFields: {
                        userType,
                    },

                    // Optional, uncomment if you are looking to use multilingual changelog
                    // locale: "en", // Will make sure the user receives the changelog email in the correct language

                    // Optional - add user company information as needed
                    companies: [
                        {
                            id: `${orgid}`, // required
                            name: dataArray.company, // required
                            createdAt: new Date(dataArray.org_createdts * 1000).toISOString(), // optional
                            // monthlySpend: 500, // optional
                        },
                    ], // optional
                },
                (err) => {
                    // Callback function. Called when identify completed.
                    if (err) {
                        // console.error(err);
                    } else {
                        // console.log("Data sent successfully!");
                    }
                }
            );
        }
    }

    const isMeteredBilling = dataArray.meteredBilling?.reference_credits;

    return {
        type: UPDATE_ACCOUNT_DETAILS,
        payload: {
            email: dataArray.email || '',
            organisation: dataArray.company || '',
            isLoggedin: data.loggedin,
            isFetchingUser: false,
            fetchedUser: true,
            reminder1,
            reminder2,
            reminder3,
            reminder4,
            roleid,
            orgid,
            meteredBilling: isMeteredBilling,
            most_overdue_invoice_days: dataArray.most_overdue_invoice_days,
            creditBal: dataArray.totalcreditbal,
            freeCreditBal: dataArray.freecreditbal,
            paidCreditBal: dataArray.paidcreditbal,
            paidRTWCreditBal: dataArray.paid_rtw_check_bal,
            isAdmin,
            isSuperAdmin,
            teamname: dataArray.teamname || '',
            fullname: dataArray.fullname || '',
            planid: dataArray.planid,
            toursEnabled: !!dataArray.tours_enabled,
        },
    };
};

export const updateCreditBalance = () => {
    return (dispatch) => {
        const url = API_URL + GET_EMPLOYER_ACCOUNT;
        const config = { withCredentials: true };

        axios
            .get(url, config)
            .then((response) => {
                if (response.data.status === 'success') {
                    const dataArray = response.data.data[0] || {};

                    dispatch({
                        type: UPDATE_CREDIT_BALANCE,
                        payload: {
                            creditBal: dataArray.totalcreditbal,
                            paidRTWCreditBal: dataArray.paid_rtw_check_bal,
                        },
                    });
                } else {
                    dispatch({
                        type: 'ERROR_UPDATING_BALANCE',
                        payload: null,
                    });
                }
            })
            .catch((error) => {
                dispatch(setNetworkError(error));
            });
    };
};

export const postLogout = () => {
    return (dispatch) => {
        const url = API_URL + POST_LOGOUT_URL;
        const config = { withCredentials: true };

        axios
            .post(url, null, config)
            .then((response) => {
                const data = response.data;
                if (data.status === 'success') {
                    track('Logged Out');
                    mixpanel.reset();
                    dispatch(loggedOut());
                    dispatch(logoutSuccess(data));

                    return window.location.assign(Routes.login);
                }
            })
            .catch((error) => {
                dispatch(setNetworkError(error));
            });
    };
};

const logoutSuccess = (data) => {
    return {
        type: LOGOUT_SUCCESS,
        payload: {
            loggedIn: data.loggedin,
            logoutMessage: data.message,
            email: '',
            organisation: '',
        },
    };
};

const loggedOut = () => {
    return {
        type: LOGGED_OUT,
        payload: {},
    };
};

export const updateTourState = (key, value) => {
    const tours = JSON.parse(localStorage.getItem('tours')) || {};
    const newState = {
        ...tours,
        [key]: value,
    };

    localStorage.setItem('tours', JSON.stringify(newState));

    return {
        type: UPDATE_TOUR_STATUS,
        payload: {
            tours: newState,
        },
    };
};
