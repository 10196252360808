import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import translations from './locales.js';
import { flattenMessages } from './flatten.js';

export const IntlWrapper = ({ children, locales }) => {
    const selectedLocale = locales.locale;
    const messages = flattenMessages(translations[selectedLocale]);

    return (
        <IntlProvider locale={selectedLocale} messages={messages}>
            {children}
        </IntlProvider>
    );
};

export const getIntl = (locales) => {
    const { locale } = locales;
    const messages = flattenMessages(translations[locale]);
    const { intl } = new IntlProvider({ locale, messages }, {}).state;

    return intl;
};

export default connect(({ locales }) => ({ locales }))(IntlWrapper);
