import { configureStore } from '@reduxjs/toolkit';

import rootReducer from '../reducers/reducers.js';
import { analytics } from '../middlewares/analytics';
import { initialState } from './initial-state';
import process from 'process';

const middlewares = [analytics];

export const getStore = (state = {}) => {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState: { ...initialState, ...state },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
        devTools: process.env.NODE_ENV !== 'production',
    });

    return { store };
};
