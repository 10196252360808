import axios from 'axios';
import * as API from '../constants/api';
import * as actions from '../constants/action-types';
import { updateLoggedInStatus } from './loginActions.js';
import { updateCreditBalance } from './userActions';
import { setNetworkError } from './siteActions';
import { getCandidates } from './candidateActions';
import { notificationMessage } from './notificationMessageActions';
import { track } from '../utils/analytics';

export const newReferenceRequest = (formData) => (dispatch) => {
    const url = API.API_URL + API.POST_REFERENCE_REQUEST_URL;
    const config = { withCredentials: true };
    const params = new URLSearchParams();

    params.append('inputname', formData.fullname);
    params.append('inputemail', formData.email);
    params.append('inputphone', formData.phone);
    params.append('inputphonetype', formData.phoneType);
    params.append('input_jobtitle', formData.jobTitle);
    params.append('inputprofileid', formData.questionProfileId);
    params.append('inputmessage', formData.customMessage);
    params.append('inputrefnum', formData.references);
    params.append('inputcan_yrequired', formData.rightToWorkMandatory ? 1 : 0);
    params.append('referer', location.href);

    Object.entries(formData.reminders).forEach(([key, value]) => {
        params.append(`inputreminder${key}`, value);
    });

    dispatch(postReferenceRequest());

    axios
        .post(url, params, config)
        .then(({ data }) => {
            dispatch(updateLoggedInStatus(data));
            if (data.status === 'success') {
                track('New Request Success', {
                    type: formData.references > 0 ? 'reference check' : 'right to work check',
                    digitalIdCheckRequired: formData.rightToWorkMandatory,
                    numberOfReferences: formData.references,
                    candidateMessageAdded: !!formData.customMessage,
                    id: data.data.rid,
                });
                dispatch(updateCreditBalance());
                dispatch(getCandidates());
                dispatch(referenceRequestSuccess(data));
            } else {
                dispatch(referenceRequestError(data.data));
            }
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

const postReferenceRequest = () => ({ type: actions.POST_REFERENCE_REQUEST });

const referenceRequestSuccess = ({ message, status, data }) => {
    const requestUrl = (data && data.requesturl) || '';
    const requestId = (data && data.rid) || '';

    return {
        type: actions.REFERENCE_REQUEST_SUCCESS,
        payload: {
            message,
            status,
            requestUrl,
            requestId,
        },
    };
};

export const clearReferenceRequest = () => ({ type: actions.CLEAR_REFERENCE_REQUEST });

const referenceRequestError =
    ({ inputname = '', inputemail = '', inputrefnum = '' }) =>
    (dispatch) => {
        dispatch({ type: actions.REFERENCE_REQUEST_ERROR });
        dispatch(
            notificationMessage({
                type: 'error',
                timeout: 10000,
                textKey: 'ReferenceRequest.errors.serverError',
                additionalMsg: `\n${inputname}\n${inputemail}\n${inputrefnum}`,
            })
        );
    };

export const getProfileList = () => (dispatch) => {
    const url = API.API_URL + API.GET_PROFILES_LIST_URL;
    const config = { withCredentials: true };

    dispatch({ type: actions.GET_PROFILE_LIST });

    axios
        .get(url, config)
        .then(({ data }) => {
            dispatch(updateLoggedInStatus(data));

            if (data.status === 'success') {
                dispatch(profileListSuccess(data));
            } else {
                dispatch(profileListError(data));
            }
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

const profileListSuccess = ({ data }) => ({
    type: actions.RECEIVED_PROFILE_LIST,
    payload: { questionProfileList: data },
});

const profileListError = ({ message }) => ({
    type: actions.ERROR_RECEIVING_PROFILE_LIST,
    payload: { questionProfileListError: message },
});

export const getQuestionProfilePreview = (id) => (dispatch) => {
    const url = API.API_URL + API.GET_QUESTION_PROFILE_URL + id;
    const config = { withCredentials: true };

    dispatch({ type: actions.GET_QUESTION_PROFILE_PREVIEW });

    axios
        .get(url, config)
        .then(({ data }) => {
            dispatch(updateLoggedInStatus(data));

            if (data.status === 'success') {
                dispatch(receivedQuestionProfilePreview(data));
            } else {
                dispatch(errorQuestionProfilePreview(data));
            }
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

const receivedQuestionProfilePreview = ({ data }) => ({
    type: actions.RECEIVED_QUESTION_PROFILE_PREVIEW,
    payload: { profile: data[0] },
});

const errorQuestionProfilePreview = ({ message }) => ({
    type: actions.ERROR_QUESTION_PROFILE_PREVIEW,
    payload: { profileErrorMessage: message },
});

export const getReferencingStats =
    (dateRange = 0) =>
    async (dispatch) => {
        dispatch({
            type: actions.LOADING_REFERENCING_STATS,
            payload: {
                loading: true,
            },
        });

        try {
            const URL = API.API_URL + API.GET_REFERENCING_DASHBOARD_STATS_URL;
            const config = {
                params: {
                    date_filter_days: dateRange,
                },
                withCredentials: true,
            };
            const { data } = await axios.get(URL, config);

            if (data.status === 'success') {
                dispatch({
                    type: actions.RECEIVED_REFERENCING_STATS,
                    payload: data.data[0],
                });
            } else {
                dispatch(
                    notificationMessage({
                        type: 'error',
                        messageArray: Object.values(data.data),
                    })
                );
            }
        } catch (error) {
            dispatch(
                notificationMessage({
                    type: 'error',
                    message: error.message,
                    timeout: 8000,
                })
            );
        } finally {
            dispatch({
                type: actions.LOADING_REFERENCING_STATS,
                payload: {
                    loading: false,
                },
            });
        }
    };
