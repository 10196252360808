import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

export function track(name, props = {}) {
    if (location.host !== 'localhost:6006' || location.host !== 'localhost:8080') {
        mixpanel.track(name, props);

        if (posthog) {
            posthog.capture(name, props);
        }
    }
}
