import * as actions from '../constants/action-types';
import axios from 'axios';

export const setLocale = (locale) => {
    window.localStorage.setItem('locale', locale);
    axios.defaults.headers.common['Accept-Language'] = locale;

    return {
        type: actions.SET_LOCALE,
        payload: {
            locale,
        },
    };
};
