import { useRef, useEffect, useState, useLayoutEffect } from 'react';

export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
};

export const useDidUpdate = (callback, deps) => {
    const hasMount = useRef(false);

    useEffect(() => {
        if (hasMount.current) {
            callback();
        } else {
            hasMount.current = true;
        }
    }, deps);
};

export const useContainerQuery = ({ el, query, xs, sm, md, lg, xl }) => {
    const [size, setSize] = useState('');
    const [queryResult, setQueryResult] = useState(false);
    const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            const width = entry.contentRect.width;

            if (xl && width >= xl) setSize('XL');
            else if (lg && width >= lg) setSize('LG');
            else if (md && width >= md) setSize('MD');
            else if (sm && width >= sm) setSize('SM');
            else if (xs && width >= xs) setSize('XS');
            else setSize('');

            if (query) {
                setQueryResult(query(width));
            }
        });
    });

    useEffect(() => {
        if (el.current) {
            observer.observe(el.current);
        }

        return () => {
            if (el?.current) {
                observer.unobserve(el.current);
            }
        };
    }, [el]);

    return [queryResult, size];
};

export function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);

    return matches;
}

export function useOnScreen(ref, rootMargin = '0px') {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin,
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.unobserve(ref.current);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return isIntersecting;
}

export function useDimensions() {
    const ref = useRef();
    const [dimensions, setDimensions] = useState({});

    useLayoutEffect(() => {
        if (ref.current) {
            setDimensions(ref.current.getBoundingClientRect().toJSON());
        }
    }, [ref.current]);

    return [ref, dimensions];
}

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
