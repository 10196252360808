import { produce } from 'immer';
import * as actions from '../constants/action-types';
import dummyCandidate from './dummyCandidate.json';

const sortByNewest = (candidates) => candidates.sort((prevKey, nextKey) => nextKey - prevKey);
const sortByOldest = (candidates) => candidates.sort((prevKey, nextKey) => prevKey - nextKey);

const transformFilterData = (data) =>
    data.map(({ name, id }) => ({
        name,
        value: id,
    }));

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.GET_CANDIDATES:
                draft.isFiltered = payload.isFiltered;

                if (payload.start === 0) {
                    draft.loadingCandidates = true;
                    draft.loadingMoreCandidates = false;
                } else {
                    draft.loadingCandidates = false;
                    draft.loadingMoreCandidates = true;
                }

                break;

            case actions.RECEIVED_CANDIDATES:
                const newCandidates = payload.candidates.reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.rid]: {
                            ...cur,
                            referencesComplete: !!cur.request_completed,
                            references: [],
                            loading: false,
                            loadingReferences: false,
                            cancellingReminders: false,
                            sendingReminder: false,
                            showMissingGapsWarning: cur?.required_range_missing > 0,
                            showDigitalIdMissingError:
                                cur?.can_yrequired === 1 &&
                                cur?.can_yverified === '0000-00-00 00:00:00' &&
                                cur.referees > 0,
                        },
                    }),
                    {}
                );
                let candidatesById;

                if (window.TOUR_ACTIVE) {
                    candidatesById = {
                        ...newCandidates,
                        ...dummyCandidate,
                    };

                    draft.activeCandidateId = 1;
                    draft.activeReferenceId = null;
                    draft.list = ['1', ...Object.keys(newCandidates)];
                } else {
                    if (payload.start) {
                        candidatesById = {
                            ...draft.byId,
                            ...newCandidates,
                        };
                    } else {
                        candidatesById = newCandidates;
                        draft.activeCandidateId = payload.activeCandidateId;
                    }

                    draft.list =
                        payload.sortOrder === 'oldest'
                            ? sortByOldest(Object.keys(candidatesById))
                            : sortByNewest(Object.keys(candidatesById));
                }

                draft.byId = candidatesById;
                draft.loadingCandidates = false;
                draft.loadingMoreCandidates = false;
                draft.hasMoreCandidates = payload.candidates.length >= 100;

                break;

            case actions.GET_CANDIDATE:
                draft.activeCandidateId = payload.candidateId;
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    loading: true,
                };
                draft.activeReferenceId = null;

                break;

            case actions.RECEIVED_CANDIDATE:
                const { candidate } = payload;

                draft.byId[candidate.rid] = {
                    ...draft.byId[candidate.rid],
                    ...candidate,
                    referencesComplete: !!candidate.request_completed,
                    loading: false,
                };

                break;

            case actions.GET_CANDIDATE_REFERENCES:
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    loadingReferences: true,
                };

                break;

            case actions.RECEIVED_CANDIDATE_REFERENCES:
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    loadingReferences: false,
                    references: payload.references,
                };

                break;

            case actions.SET_ACTIVE_REFERENCE:
                draft.activeReferenceId = payload.activeReferenceId;

                break;

            case actions.DELETE_CANDIDATE:
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    deleting: true,
                };

                break;

            case actions.CANDIDATE_DELETED:
                draft.activeCandidateId = payload.nextCandidateId;
                delete draft.byId[payload.candidateId];
                draft.list = draft.list.filter((key) => key !== String(payload.candidateId));
                draft.activeReferenceId = null;

                break;

            case actions.CANDIDATE_DELETE_FAILED:
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    deleting: false,
                };

                break;

            case actions.SET_CANCEL_REMINDER_LOADER:
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    cancellingReminders: payload.isCancelling,
                };

                break;

            case actions.SET_SEND_REMINDER_LOADER:
                draft.byId[payload.candidateId] = {
                    ...draft.byId[payload.candidateId],
                    sendingReminder: payload.isSendingReminder,
                };

                break;

            case actions.REFERENCE_REQUEST_SUCCESS:
                draft.activeReferenceId = null;

                break;

            case actions.DELETE_REFERENCE:
                draft.byId[draft.activeCandidateId] = {
                    ...draft.byId[draft.activeCandidateId],
                    deletingReference: true,
                };

                break;

            case actions.DELETE_REFERENCE_SUCCESS:
            case actions.DELETE_REFERENCE_FAILED:
                draft.byId[draft.activeCandidateId] = {
                    ...draft.byId[draft.activeCandidateId],
                    deletingReference: false,
                };

                break;

            case actions.EDIT_NUM_OF_REFERENCES:
                draft.byId[draft.activeCandidateId] = {
                    ...draft.byId[draft.activeCandidateId],
                    editingNumOfReferences: true,
                };

                break;

            case actions.EDIT_NUM_OF_REFERENCES_SUCCESS:
            case actions.EDIT_NUM_OF_REFERENCES_FAILED:
                draft.byId[draft.activeCandidateId] = {
                    ...draft.byId[draft.activeCandidateId],
                    editingNumOfReferences: false,
                };

                break;

            case actions.GET_FILTER_DATA:
                draft.loadingFilterData = true;

                break;

            case actions.RECEIVED_FILTER_DATA:
                draft.loadingFilterData = false;
                draft.filterData = {
                    users: transformFilterData(payload.users),
                    teams: transformFilterData(payload.teams),
                };

                break;

            case actions.FILTER_DATA_ERROR:
                draft.loadingFilterData = false;
                draft.filterData = {
                    users: [],
                    teams: [],
                };

                break;

            case 'SETUP_DUMMY_REQUEST':
                draft.byId = dummyCandidate;
                // draft.list = ['61836'];
                draft.activeCandidateId = 61836;
                draft.activeReferenceId = null;

                break;
        }
    });
