import React from 'react';
import { useIntl } from 'react-intl';
import { Button, ListItemButton, styled } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { NAV_HEIGHT } from './';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-selected': {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        ':hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    fontWeight: 400,
    flexGrow: 0,
    height: `calc(${NAV_HEIGHT} - 1px)`,
    ':hover': {
        backgroundColor: theme.palette.secondary.light,
    },
    borderRadius: 0,
}));

const NavigationButton = ({ id, selected, anchorEl, labelId, menuId, onOpen }) => {
    const { formatMessage } = useIntl();

    return (
        <StyledListItemButton
            id={id}
            focusRipple
            aria-owns={anchorEl ? menuId : undefined}
            // aria-controls={menuId}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            onClick={onOpen}
            size="large"
            color="inherit"
            component={Button}
            selected={selected}
            endIcon={
                <ExpandMoreOutlined
                    sx={{
                        transition: 'all 0.2s ease-in',
                        rotate: anchorEl ? '180deg' : 0,
                    }}
                />
            }
        >
            {formatMessage({ id: labelId })}
        </StyledListItemButton>
    );
};

export default NavigationButton;
