import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { track } from '../utils/analytics';
import { useIntl } from 'react-intl';
import { Box, Button, Container, Card, CardContent, Typography, Divider } from '@mui/material';

import Logo from '../../assets/refnow-brand-logo.svg';

const Errorpage = ({}) => {
    const { data = '', status = '', statusText = '' } = useRouteError();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (status) {
            track('Error page', {
                message: data,
                status,
                statusText,
            });
        }
    }, []);

    return (
        <Container maxWidth="sm">
            <Box mt={8}>
                <Box display="flex" mb={2} justifyContent="center">
                    <Logo alt="RefNow" title="RefNow" height="50" width="160" />
                </Box>
                <Card>
                    <CardContent
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography>{formatMessage({ id: 'Errors.404.title' })}</Typography>
                        <Divider width="100%" sx={{ my: 1.5 }} />
                        <Box>
                            <Box textAlign="center">
                                <Typography>
                                    {formatMessage(
                                        { id: 'Errors.404.message' },
                                        { b: (chunks) => <b>{chunks}</b> }
                                    )}
                                </Typography>
                                <Box display="flex" gap={2} mt={3} justifyContent="center">
                                    <Button
                                        href="https://support.refnow.com"
                                        target="_blank"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            '&:hover': {
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        {formatMessage({ id: 'Errors.404.supportCentre' })}
                                    </Button>
                                    <Button
                                        href="https://support.refnow.com/hc/en-gb/requests/new"
                                        target="_blank"
                                        color="primary"
                                        variant="text"
                                    >
                                        {formatMessage({ id: 'Errors.404.submitTicket' })}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default Errorpage;
