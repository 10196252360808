import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.SET_LOCALE:
                draft.locale = payload.locale;

                break;
        }
    });
