import axios from 'axios';
import {
    API_URL,
    POST_LOGIN_URL,
    POST_ACTIVATION_URL,
    POST_SSO_LOGIN_URL,
} from '../constants/api.js';
import * as actions from '../constants/action-types';
import { updateUserInfo } from './userActions.js';
import { setNetworkError } from '../actions/siteActions';
import { track } from '../utils/analytics';

export const login =
    ({ email, password }) =>
    (dispatch) => {
        dispatch({ type: actions.LOGIN_REQUEST });

        const url = API_URL + POST_LOGIN_URL;
        const params = new URLSearchParams();
        const config = { withCredentials: true };

        params.append('inputEmail', email);
        params.append('inputPassword', password);
        params.append('referer', location.href);

        axios
            .post(url, params, config)
            .then(({ data }) => {
                if (data.status === 'success') {
                    track('Logged In');
                    dispatch({
                        type: actions.LOGIN_SUCCESS,
                        payload: {
                            loginMessage: data.message,
                            referer: location.href,
                        },
                    });
                    dispatch(updateUserInfo(email));
                    dispatch(setRedirectUrl(null));
                } else {
                    track('Login Error', {
                        email,
                        error: data.message,
                    });
                    dispatch({
                        type: actions.LOGIN_ERROR,
                        payload: {
                            errorMessage: data.message,
                        },
                    });
                }
            })
            .catch((error) => {
                dispatch(setNetworkError(error));
            });
    };

export const updateLoggedInStatus = (data) => ({
    type: actions.UPDATE_LOGGED_IN_STATUS,
    payload: {
        isLoggedin: data.loggedin,
        error: data.loggedin === 0 ? data.message : '',
    },
});

export const activateEmail = (activationToken) => (dispatch) => {
    const url = API_URL + POST_ACTIVATION_URL;
    const params = new URLSearchParams();
    const config = { withCredentials: true };

    params.append('acti', activationToken);

    dispatch({ type: actions.ACTIVATING_EMAIL });

    axios
        .post(url, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch({
                    type: actions.ACTIVATION_SUCCESS,
                    payload: {
                        activationMessage: data.message,
                    },
                });
            } else {
                dispatch({
                    type: actions.ACTIVATION_ERROR,
                    payload: {
                        activationMessage: data.message,
                    },
                });
            }
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

export const setRedirectUrl = (redirectUrl) => ({
    type: actions.SET_REDIRECT_URL,
    payload: {
        redirectUrl,
    },
});

export const checkSSO = async (email) => {
    if (!email.length) return null;

    const url = API_URL + POST_SSO_LOGIN_URL;
    const params = new URLSearchParams();
    const config = { withCredentials: true };

    params.append('inputEmail', email);

    try {
        const { data } = await axios.post(url, params, config);

        if (data.status === 'success' && data.data.sso_active) {
            track('SSO Login');
            window.location.replace(data.data.sso_redirect);
        }
    } catch ({ message }) {
        throw Error(`There was an error verifying your SSO login details: ${message}`);
    }
};
