import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import { track } from '../../../utils/analytics';

import {
    clearReferenceRequest,
    getQuestionProfilePreview,
    newReferenceRequest,
} from '../../../actions/referenceRequestActions';
import routes from '../../../constants/routes';

import InfoIcon from '../../../../assets/info-icon.svg';
import * as Styled from './styled';

import { SpinnerWithWrapper } from '../../../components/common/spinner';
import QuestionsPreview from './questions-preview';
import RemindersPreview from './reminders-preview';
import SuccessMessage from './success-message';

import CloseIcon from '../../../../assets/close-ref-icon.svg';
import NewRequestIcon from '../../../../assets/new-request-icon.svg';

import { HelpOutlineOutlined, Info, Visibility } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    ListSubheader,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import MuiTelInput from '../../../components/common/mui-tel-input';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const ReferenceRequest = ({
    clearReferenceRequest,
    reminder1,
    reminder2,
    reminder3,
    reminder4,
    isMobile,
    onClose = () => {},
    open,
    creditBalance,
    digitalIdCreditBalance,
    meteredBilling,
    loadingContent = false,
    isSuperAdmin,
    newReferenceRequest,
    questionPreview,
    profileList = [],
    requestCreated,
    requestId,
    sendingRequest,
    getQuestionProfilePreview,
}) => {
    const defaultReminders = {
        1: reminder1,
        2: reminder2,
        3: reminder3,
        4: reminder4,
    };
    const { formatMessage } = useIntl();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setError,
        clearErrors,
        getValues,
        setValue,
        reset,
        watch,
        trigger,
        getFieldState,
    } = useForm({
        defaultValues: {
            fullname: '',
            email: '',
            phone: '',
            customMessage: '',
            questionProfileId: '',
            references: 2,
            rightToWorkMandatory: false,
        },
    });
    const questionProfileList = profileList.map(
        ({ name, qpid, is_rtw_check, can_yrequired, can_message }) => ({
            name,
            value: qpid,
            rtwCheck: !!is_rtw_check,
            rtwCheckMandatory: !!can_yrequired,
            customMessage: can_message,
        })
    );
    const [rightToWorkCheckProfile, setRightToWorkCheckProfile] = useState(null);
    const numOfReferencesList = Array.from({ length: 10 }, (_, i) => i + 1).map((value) => ({
        name: formatMessage({ id: 'ReferenceRequest.labels.references' }, { references: value }),
        value,
    }));
    const [reminders, setReminders] = useState(defaultReminders);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [showCreditsCost, setShowCreditsCost] = useState(false);
    const [intlTelInput, setIntlTelInput] = useState(null);

    useEffect(() => {
        if (!open) {
            resetForm();
            setModalOpen(false);
        }
    }, [open]);

    useEffect(() => {
        if (Object.keys(errors).length) {
            const errorObj = Object.entries(errors).reduce((acc, [key, value]) => {
                return {
                    ...acc,
                    [key]: value.message,
                };
            }, {});

            track('Request form errors', {
                errors: errorObj,
            });
        }
    }, [errors]);

    useEffect(() => {
        const subscription = watch(({ questionProfileId }) => {
            if (questionProfileId) {
                setShowCreditsCost(true);
            } else {
                setShowCreditsCost(false);
            }
            trigger('rightToWorkMandatory');
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [watch]);

    const onSubmit = (data) => {
        const formData = {
            ...data,
            reminders,
            phone: intlTelInput?.getNumber(),
            phoneType: intlTelInput?.getNumberType(),
        };

        if (rightToWorkCheckProfile) {
            formData.customMessage = '';
            formData.references = 0;
            formData.rightToWorkMandatory = true;
        }

        track('Submit request form', { isMobile });

        newReferenceRequest(formData);
    };

    const resetForm = () => {
        clearErrors();
        reset();
        setRightToWorkCheckProfile(null);
        clearReferenceRequest();
    };

    const handleClose = (e) => {
        e.preventDefault();
        requestCreated && clearReferenceRequest();
        onClose();
    };
    const handleModal = (event, type = '') => {
        event && event.preventDefault();

        setModalContent(type);
        setModalOpen(!modalOpen);
    };
    const questionsPreviewId = 'question-profile-preview-modal';
    const remindersPreviewId = 'reminders-preview-modal;';

    const ModalOptions = {
        questions: (
            <QuestionsPreview
                id={questionsPreviewId}
                questionPreview={questionPreview}
                questionProfileId={getValues('questionProfileId')}
                getQuestionProfilePreview={getQuestionProfilePreview}
            />
        ),
        reminders: (
            <RemindersPreview
                id={remindersPreviewId}
                defaultReminderOptions={reminders}
                setReminders={setReminders}
                onClose={handleModal}
            />
        ),
    };

    return (
        <Styled.Container>
            <Styled.RequestContainer id="new-request-form-container">
                <Styled.FormContainer isMobile={isMobile}>
                    <Styled.CloseButton
                        type="button"
                        onClick={handleClose}
                        aria-label="Close reference request form"
                        disabled={sendingRequest}
                        id="request-close-button"
                    >
                        <CloseIcon />
                    </Styled.CloseButton>
                    {requestCreated ? (
                        <SuccessMessage
                            creditBalance={creditBalance}
                            onClose={handleClose}
                            startNewRefRequest={resetForm}
                            requestId={requestId}
                        />
                    ) : (
                        <>
                            <Styled.FormTitle id="new-request-form">
                                <NewRequestIcon />
                                {formatMessage({ id: 'ReferenceRequest.title' })}
                            </Styled.FormTitle>
                            <Typography variant="paragraph" my={1} color="#626263">
                                {formatMessage({ id: 'ReferenceRequest.creatingRequestMessage' })}
                            </Typography>
                            {isSuperAdmin && creditBalance < 3 && !meteredBilling && (
                                <Styled.CreditsBanner tabIndex={0}>
                                    <InfoIcon />
                                    <p>
                                        {formatMessage(
                                            {
                                                id: 'ReferenceRequest.topUpCreditsAdmin',
                                            },
                                            {
                                                a: (linkTxt) => (
                                                    <a href={routes.settings.buyCredits}>
                                                        {linkTxt}
                                                    </a>
                                                ),
                                            }
                                        )}
                                    </p>
                                </Styled.CreditsBanner>
                            )}
                            {!isSuperAdmin && creditBalance < 3 && !meteredBilling && (
                                <Styled.CreditsBanner tabIndex={0}>
                                    <InfoIcon />
                                    <p>
                                        {formatMessage({
                                            id: 'ReferenceRequest.topUpCredits',
                                        })}
                                    </p>
                                </Styled.CreditsBanner>
                            )}
                            <Styled.Form onSubmit={handleSubmit(onSubmit)}>
                                <Styled.FormBody>
                                    <Box mb={1}>
                                        <Typography
                                            sx={{ fontSize: '14px', color: '#626263' }}
                                            variant="caption"
                                            display="block"
                                        >
                                            {formatMessage({
                                                id: 'ReferenceRequest.labels.candidateDetails',
                                            })}
                                        </Typography>
                                        <Divider />
                                    </Box>
                                    <Grid
                                        container
                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        id="candidate-details"
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <Styled.FormRow>
                                                <TextField
                                                    data-intercom-target="new_reference_request_can_name"
                                                    fullWidth
                                                    autoFocus
                                                    id="candidate-full-name"
                                                    name="fullname"
                                                    type="text"
                                                    disabled={sendingRequest}
                                                    label={formatMessage({
                                                        id: 'ReferenceRequest.labels.fullName',
                                                    })}
                                                    placeholder={formatMessage({
                                                        id: 'ReferenceRequest.placeholders.fullName',
                                                    })}
                                                    variant="outlined"
                                                    error={!!errors.fullname}
                                                    helperText={
                                                        !!errors.fullname && errors.fullname.message
                                                    }
                                                    {...register('fullname', {
                                                        required: formatMessage({
                                                            id: 'ReferenceRequest.errors.required',
                                                        }),
                                                    })}
                                                />
                                            </Styled.FormRow>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Styled.FormRow>
                                                <TextField
                                                    fullWidth
                                                    id="candidate-email"
                                                    data-public
                                                    name="email"
                                                    type="email"
                                                    disabled={sendingRequest}
                                                    label={formatMessage({
                                                        id: 'ReferenceRequest.labels.email',
                                                    })}
                                                    placeholder={formatMessage({
                                                        id: 'ReferenceRequest.placeholders.email',
                                                    })}
                                                    variant="outlined"
                                                    error={!!errors.email}
                                                    helperText={
                                                        !!errors.email && errors.email.message
                                                    }
                                                    {...register('email', {
                                                        required: formatMessage({
                                                            id: 'ReferenceRequest.errors.required',
                                                        }),
                                                        validate: (value) =>
                                                            isEmail(value) ||
                                                            formatMessage({
                                                                id: 'ReferenceRequest.errors.email',
                                                            }),
                                                    })}
                                                />
                                            </Styled.FormRow>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Styled.FormRow>
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        validate: () =>
                                                            intlTelInput?.isValidNumber() ||
                                                            formatMessage({
                                                                id: 'ReferenceRequest.errors.invalidNumber',
                                                            }),
                                                        required: formatMessage({
                                                            id: 'ReferenceRequest.errors.required',
                                                        }),
                                                    }}
                                                    name="phone"
                                                    id="candidate-phonenumber"
                                                    render={({
                                                        fieldState: { invalid, error },
                                                    }) => (
                                                        <MuiTelInput
                                                            isValidCheck={({ target }) => {
                                                                if (intlTelInput.getNumber()) {
                                                                    setValue('phone', target.value);
                                                                }

                                                                clearErrors('phone');

                                                                if (
                                                                    intlTelInput.getNumber() &&
                                                                    !intlTelInput?.isValidNumber()
                                                                ) {
                                                                    setError('phone', {
                                                                        type: 'custom',
                                                                        message: formatMessage({
                                                                            id: 'ReferenceRequest.errors.invalidNumber',
                                                                        }),
                                                                    });
                                                                }
                                                            }}
                                                            setIntlTelInput={setIntlTelInput}
                                                            fullWidth
                                                            disabled={sendingRequest}
                                                            label={formatMessage({
                                                                id: 'ReferenceRequest.labels.phone',
                                                            })}
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            error={invalid}
                                                            helperText={invalid && error.message}
                                                        />
                                                    )}
                                                />
                                            </Styled.FormRow>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Styled.FormRow id="candidate-job-title">
                                                <TextField
                                                    fullWidth
                                                    name="jobTitle"
                                                    type="text"
                                                    disabled={sendingRequest}
                                                    label={formatMessage({
                                                        id: 'ReferenceRequest.labels.jobTitle',
                                                    })}
                                                    placeholder={formatMessage({
                                                        id: 'ReferenceRequest.placeholders.jobTitle',
                                                    })}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                    title={formatMessage({
                                                                        id: 'ReferenceRequest.tooltips.jobTitle',
                                                                    })}
                                                                    tabIndex={0}
                                                                >
                                                                    <HelpOutlineOutlined />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    maxRows={4}
                                                    variant="outlined"
                                                    {...register('jobTitle')}
                                                />
                                            </Styled.FormRow>
                                        </Grid>
                                        {!rightToWorkCheckProfile && (
                                            <Grid item xs={12} sm={12}>
                                                <Styled.FormRow>
                                                    <TextField
                                                        id="candidate-custom-message"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        fullWidth
                                                        name="customMessage"
                                                        type="text"
                                                        multiline
                                                        disabled={sendingRequest}
                                                        aria-label="candidate-custom-message"
                                                        label={formatMessage({
                                                            id: 'ReferenceRequest.labels.customMessage',
                                                        })}
                                                        placeholder={formatMessage({
                                                            id: 'ReferenceRequest.placeholders.customMessage',
                                                        })}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip
                                                                        title={formatMessage({
                                                                            id: 'ReferenceRequest.tooltips.customMessage',
                                                                        })}
                                                                        tabIndex={0}
                                                                    >
                                                                        <HelpOutlineOutlined />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        maxRows={4}
                                                        variant="outlined"
                                                        {...register('customMessage')}
                                                    />
                                                </Styled.FormRow>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Box my={1} mt={2}>
                                        <Typography
                                            sx={{ fontSize: '14px', color: '#626263' }}
                                            variant="caption"
                                            display="block"
                                        >
                                            {formatMessage({
                                                id: 'ReferenceRequest.labels.requestSettings',
                                            })}
                                        </Typography>
                                        <Divider />
                                    </Box>
                                    <Grid
                                        container
                                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                        id="request-settings"
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <Styled.FormRow>
                                                <Controller
                                                    id="candidate-question-profile"
                                                    name="questionProfileId"
                                                    control={control}
                                                    rules={{
                                                        required: formatMessage({
                                                            id: 'ReferenceRequest.errors.required',
                                                        }),
                                                    }}
                                                    render={({
                                                        field,
                                                        fieldState: { invalid, error },
                                                    }) => (
                                                        <TextField
                                                            {...field}
                                                            onChange={({ target }) => {
                                                                const profile =
                                                                    questionProfileList.find(
                                                                        (profile) =>
                                                                            profile.value ===
                                                                            target.value
                                                                    );

                                                                if (profile) {
                                                                    setRightToWorkCheckProfile(
                                                                        profile.rtwCheck
                                                                    );
                                                                    setValue(
                                                                        'rightToWorkMandatory',
                                                                        profile.rtwCheckMandatory
                                                                    );

                                                                    if (!profile.rtwCheck) {
                                                                        if (
                                                                            !getFieldState(
                                                                                'customMessage'
                                                                            ).isDirty
                                                                        ) {
                                                                            setValue(
                                                                                'customMessage',
                                                                                profile.customMessage
                                                                            );
                                                                        }
                                                                    }
                                                                }

                                                                field.onChange(target.value);
                                                            }}
                                                            id="candidate-question-profile"
                                                            fullWidth
                                                            select
                                                            SelectProps={{
                                                                MenuProps,
                                                                ...(getValues(
                                                                    'questionProfileId'
                                                                ) && { IconComponent: () => null }),
                                                            }}
                                                            disabled={sendingRequest}
                                                            label={formatMessage({
                                                                id: 'ReferenceRequest.labels.questionProfile',
                                                            })}
                                                            aria-labelledby="candidate-question-profile"
                                                            placeholder={formatMessage({
                                                                id: 'ReferenceRequest.placeholders.questionProfile',
                                                            })}
                                                            variant="outlined"
                                                            error={invalid}
                                                            helperText={invalid && error.message}
                                                            InputProps={{
                                                                ...(getValues(
                                                                    'questionProfileId'
                                                                ) &&
                                                                    !rightToWorkCheckProfile && {
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle preview visibility"
                                                                                    title="Preview questions"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        track(
                                                                                            'Questions profile preview'
                                                                                        );
                                                                                        handleModal(
                                                                                            e,
                                                                                            'questions'
                                                                                        );
                                                                                    }}
                                                                                    edge="end"
                                                                                >
                                                                                    <Visibility />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }),
                                                            }}
                                                        >
                                                            <ListSubheader color="primary">
                                                                {formatMessage({
                                                                    id: 'ReferenceRequest.labels.subHeaderRightToWork',
                                                                })}
                                                            </ListSubheader>
                                                            {!questionProfileList.filter(
                                                                ({ rtwCheck }) => rtwCheck
                                                            ).length ? (
                                                                <MenuItem disabled key="noItems">
                                                                    {formatMessage({
                                                                        id: 'ReferenceRequest.labels.noRightToWorkProfiles',
                                                                    })}
                                                                </MenuItem>
                                                            ) : (
                                                                questionProfileList
                                                                    .filter(
                                                                        ({ rtwCheck }) => rtwCheck
                                                                    )
                                                                    .map(({ name, value }) => (
                                                                        <MenuItem
                                                                            key={value}
                                                                            value={value}
                                                                        >
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))
                                                            )}
                                                            <ListSubheader
                                                                sx={{ color: '#916fff' }}
                                                            >
                                                                {formatMessage({
                                                                    id: 'ReferenceRequest.labels.subHeaderQuestionProfiles',
                                                                })}
                                                            </ListSubheader>
                                                            {questionProfileList
                                                                .filter(({ rtwCheck }) => !rtwCheck)
                                                                .map(({ name, value }) => (
                                                                    <MenuItem
                                                                        key={value}
                                                                        value={value}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                        </TextField>
                                                    )}
                                                />
                                            </Styled.FormRow>
                                        </Grid>
                                        {!rightToWorkCheckProfile && (
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <Styled.FormRow>
                                                        <Controller
                                                            id="candidate-references"
                                                            name="references"
                                                            control={control}
                                                            rules={{
                                                                required: formatMessage({
                                                                    id: 'ReferenceRequest.errors.required',
                                                                }),
                                                            }}
                                                            render={({
                                                                field,
                                                                fieldState: { invalid, error },
                                                            }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    select
                                                                    SelectProps={{
                                                                        MenuProps,
                                                                    }}
                                                                    disabled={sendingRequest}
                                                                    label={formatMessage({
                                                                        id: 'ReferenceRequest.labels.refsRequired',
                                                                    })}
                                                                    variant="outlined"
                                                                    error={invalid}
                                                                    helperText={
                                                                        invalid && error.message
                                                                    }
                                                                >
                                                                    {numOfReferencesList.map(
                                                                        ({ name, value }) => (
                                                                            <MenuItem
                                                                                key={value}
                                                                                value={value}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </TextField>
                                                            )}
                                                        />
                                                    </Styled.FormRow>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Styled.FormRow>
                                                        <Box display="flex" alignItems="baseline">
                                                            <FormControlLabel
                                                                label={formatMessage({
                                                                    id: 'ReferenceRequest.labels.rtwCheckRequired',
                                                                })}
                                                                aria-label="right-to-work-check"
                                                                id="candidate-right-to-work-checkbox"
                                                                control={
                                                                    <Controller
                                                                        name="rightToWorkMandatory"
                                                                        control={control}
                                                                        disabled={sendingRequest}
                                                                        render={({ field }) => (
                                                                            <Checkbox
                                                                                {...field}
                                                                                checked={
                                                                                    field.value
                                                                                }
                                                                                onChange={({
                                                                                    target,
                                                                                }) =>
                                                                                    field.onChange(
                                                                                        target.checked
                                                                                    )
                                                                                }
                                                                                inputProps={{
                                                                                    'aria-label':
                                                                                        'right-to-work-check',
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (
                                                                                        e.key ===
                                                                                        'Enter'
                                                                                    ) {
                                                                                        e.preventDefault();
                                                                                        field.onChange(
                                                                                            !field.value
                                                                                        ); // Toggle checkbox value
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                }
                                                            />
                                                            <Tooltip
                                                                title={
                                                                    <Box
                                                                        sx={{
                                                                            a: {
                                                                                fontSize: '12px',
                                                                                textDecoration:
                                                                                    'underline',
                                                                                color: 'white',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Typography variant="caption">
                                                                            {formatMessage({
                                                                                id: 'ReferenceRequest.digitalIdInfoMsg',
                                                                            })}
                                                                        </Typography>
                                                                        <Divider
                                                                            sx={{
                                                                                margin: '5px 0',
                                                                                borderColor:
                                                                                    'white',
                                                                            }}
                                                                        />
                                                                        <Typography variant="caption">
                                                                            {formatMessage(
                                                                                {
                                                                                    id: 'ReferenceRequest.rtwCheckInfoMsg',
                                                                                },
                                                                                {
                                                                                    a: (
                                                                                        linkTxt
                                                                                    ) => (
                                                                                        <a
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            href="https://www.gov.uk/government/publications/digital-identity-certification-for-right-to-work-right-to-rent-and-criminal-record-checks/digital-identity-certification-for-right-to-work-right-to-rent-and-criminal-record-checks"
                                                                                        >
                                                                                            {
                                                                                                linkTxt
                                                                                            }
                                                                                        </a>
                                                                                    ),
                                                                                }
                                                                            )}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            >
                                                                <IconButton
                                                                    aria-label="toggle preview visibility"
                                                                    onClick={() =>
                                                                        track(
                                                                            'View right-to-work info tooltip'
                                                                        )
                                                                    }
                                                                >
                                                                    <Info />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </Styled.FormRow>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="request-reminders"
                                                variant="outlined"
                                                color="secondary"
                                                // size="large"
                                                onClick={(e) => handleModal(e, 'reminders')}
                                                disabled={sendingRequest}
                                            >
                                                {formatMessage({
                                                    id: 'ReferenceRequest.buttons.reminders',
                                                })}
                                            </Button>
                                        </Grid>
                                        {showCreditsCost && (
                                            <Grid item xs={12} sm="auto">
                                                <Styled.FormRow>
                                                    <Alert severity="info" sx={{ py: 0 }}>
                                                        {!rightToWorkCheckProfile ? (
                                                            getValues('rightToWorkMandatory') ? (
                                                                <Typography>
                                                                    {formatMessage(
                                                                        {
                                                                            id: 'ReferenceRequest.referenceRequestAndRightToWorkCheck',
                                                                        },
                                                                        {
                                                                            b: (txt) => (
                                                                                <b key={txt}>
                                                                                    {txt}
                                                                                </b>
                                                                            ),
                                                                        }
                                                                    )}
                                                                </Typography>
                                                            ) : (
                                                                <Typography>
                                                                    {formatMessage(
                                                                        {
                                                                            id: 'ReferenceRequest.referenceRequestOnly',
                                                                        },
                                                                        {
                                                                            b: (txt) => (
                                                                                <b>{txt}</b>
                                                                            ),
                                                                        }
                                                                    )}
                                                                </Typography>
                                                            )
                                                        ) : (
                                                            <Typography>
                                                                {formatMessage(
                                                                    {
                                                                        id: 'ReferenceRequest.rightToWorkCheckOnly',
                                                                    },
                                                                    {
                                                                        b: (txt) => <b>{txt}</b>,
                                                                    }
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </Alert>
                                                </Styled.FormRow>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Styled.FormBody>
                                <Styled.FormFooter>
                                    <Styled.ButtonContainer>
                                        {!!Object.keys(errors).length && (
                                            <div>
                                                <p>
                                                    {formatMessage({
                                                        id: 'ReferenceRequest.errors.requiredFields',
                                                    })}
                                                </p>
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {/* <Styled.CreditsContainer>
                                                <Box>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {formatMessage(
                                                            {
                                                                id: 'Header.account.creditsRemaining',
                                                            },
                                                            {
                                                                b: (txt) => <b>{txt}</b>,
                                                                creditsBalance: creditBalance,
                                                            }
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {formatMessage(
                                                            {
                                                                id: 'Header.account.digitalIdCreditsRemaining',
                                                            },
                                                            {
                                                                b: (txt) => <b>{txt}</b>,
                                                                creditsBalance:
                                                                    digitalIdCreditBalance,
                                                            }
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Styled.CreditsContainer> */}
                                            <div>
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    size="large"
                                                    onClick={handleClose}
                                                    type="button"
                                                    disabled={sendingRequest}
                                                >
                                                    {formatMessage({
                                                        id: 'ReferenceRequest.buttons.cancel',
                                                    })}
                                                </Button>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    loading={sendingRequest}
                                                    data-intercom-target="new_reference_request_send"
                                                    type="submit"
                                                    id="send-request-btn"
                                                >
                                                    {formatMessage({
                                                        id: 'ReferenceRequest.buttons.sendRequest',
                                                    })}
                                                </LoadingButton>
                                            </div>
                                        </div>
                                    </Styled.ButtonContainer>
                                </Styled.FormFooter>
                            </Styled.Form>
                        </>
                    )}
                </Styled.FormContainer>
                <Dialog
                    open={modalOpen}
                    maxWidth="lg"
                    disableEscapeKeyDown
                    keepMounted
                    onClose={() => setModalOpen(false)}
                    aria-modal="true"
                    aria-labelledby={
                        modalContent === 'questions' ? questionsPreviewId : remindersPreviewId
                    }
                    aria-describedby="modal-preview"
                >
                    <DialogContent sx={{ minHeight: '70vh' }}>
                        <div>
                            <Styled.CloseButton
                                type="button"
                                aria-label="Close modal"
                                onClick={handleModal}
                            >
                                <CloseIcon />
                            </Styled.CloseButton>
                            {loadingContent ? <SpinnerWithWrapper /> : ModalOptions[modalContent]}
                        </div>
                    </DialogContent>
                </Dialog>
            </Styled.RequestContainer>
        </Styled.Container>
    );
};

const mapStateToProps = ({ referenceRequest, user }) => ({
    creditBalance: user.creditBal,
    digitalIdCreditBalance: user.paidRTWCreditBal,
    meteredBilling: user.meteredBilling,
    reminder1: user.reminder1,
    reminder2: user.reminder2,
    reminder3: user.reminder3,
    reminder4: user.reminder4,
    profileList: referenceRequest.questionProfileList,
    questionPreview: referenceRequest.questionPreview,
    sendingRequest: referenceRequest.isPostingRequest,
    requestCreated: referenceRequest.requestCreated,
    requestId: referenceRequest.requestId,
});

const mapDispatchToProps = {
    clearReferenceRequest,
    getQuestionProfilePreview,
    newReferenceRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceRequest);
