import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider as Provider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
    posthog.init('phc_EhCuQAUifoqif10jaJgw6MesVgpNUsXu0Dr8bhRv8GL', {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
        capture_pageview: false,
        session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
                password: true, // Highly recommended as a minimum!!
                // email: true,
                // color: false,
                // date: false,
                // 'datetime-local': false,
                // month: false,
                // number: false,
                // range: false,
                // search: false,
                // tel: false,
                // text: false,
                // time: false,
                // url: false,
                // week: false,
                // textarea: false,
                // select: false,
            },
        },
    });
}

export function PostHogProvider({ children }) {
    return <Provider client={posthog}>{children}</Provider>;
}
