import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
`;

export const OutletContainer = styled.div`
    padding: 40px;
    /* padding: ${({ isMobile }) => (isMobile ? '40px' : '5% 15% 0')}; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    overflow: auto;
`;

export const ShapeContainer = styled.div`
    position: absolute;
    bottom: -2%;
    right: -5%;
`;

export const SplashScreenPanel = styled.div`
    @media (max-width: 850px) {
        display: none;
    }

    flex: 2;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;

    > div {
        padding: 0 54px;
        margin-top: -15%;

        > svg {
            width: 80%;
            height: 55%;
            max-width: 320px;
            cursor: pointer;
        }

        > h1 {
            font-size: 1.9rem;
            font-weight: 400;
            line-height: 1.3;
            color: ${({ theme }) => theme.palette.grey[100]};

            > span {
                color: ${({ theme }) => theme.palette.tertiary[400]};
                font-weight: 700;
            }
        }
    }
`;

export const FooterTerms = styled.p`
    color: ${({ theme }) => theme.palette.common.white};
    font-size: 12px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    opacity: 0.8;

    > a {
        text-decoration: underline;
        color: ${({ theme }) => theme.palette.common.white};

        :hover {
            color: ${({ theme }) => theme.palette.primary[400]};
        }
    }
`;

export const FormPanel = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.common.white};

    > div {
        display: flex;

        :nth-of-type(1) {
            position: absolute;
            right: 2%;
        }
    }
`;

export const LocaleSelectorContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-right: 30px;
    /* margin-top: ${({ isMobile }) => (isMobile ? '40px' : '20px')};
    margin-right: ${({ isMobile }) => (isMobile ? '30px' : '0px')}; */

    > select {
        /* background-color: ${({ theme }) => theme.primaryLight}; */
        padding: 5px 8px;
    }
`;
