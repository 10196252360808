const lang = window.localStorage.getItem('locale');
const tours = JSON.parse(window.localStorage.getItem('tours')) || null;

const defaultToursState = {
    welcomeTour: 'incomplete',
    totalStepsLeft: null,
};

if (!tours || !Object.keys(tours).length) {
    window.localStorage.setItem('tours', JSON.stringify(defaultToursState));
}

export const initialState = {
    login: {
        errorMessage: '',
        isLoggedin: false,
        isLoggingIn: false,
        loginMessage: '',
        redirectUrl: null,
        activationMessage: '',
        activationStatus: '',
    },
    register: {
        errorMessage: '',
        isRegistered: false,
        isRegistering: false,
        registrationMessage: '',
    },
    candidates: {
        activeCandidateId: null,
        activeReferenceId: null,
        byId: null,
        filterData: {
            users: [],
            teams: [],
        },
        list: [],
        isFiltered: false,
        loadingCandidates: false,
        hasMoreCandidates: true,
    },
    inbound: {
        loading: false,
    },
    referencingDashboard: {
        loading: false,
    },
    referenceRequest: {
        isPostingRequest: false,
        message: '',
        status: '',
        requestCreated: false,
        requestUrl: '',
        requestId: '',
        errors: {},
        isFetchingProfileList: false,
        questionProfileList: [],
        questionProfileListError: '',
        questionPreview: {
            isFetchingProfile: false,
            profile: {},
            profileErrorMessage: '',
        },
    },
    user: {
        email: '',
        organisation: '',
        isUpdating: false,
        message: '',
        error: '',
        isLoggedin: 0,
        isFetchingUser: false,
        fetchedUser: false,
        roleid: null,
        isAdmin: null,
        isSuperAdmin: null,
        tours: tours || defaultToursState,
    },
    usersAndTeams: {
        loadingTeams: false,
        loadingUsers: false,
        teams: [],
        users: [],
        savedTeam: false,
        savedUser: false,
        savingTeam: false,
        savingUser: false,
        deletingUser: false,
        deletingTeam: false,
    },
    site: {
        deviceWidth: null,
        deviceHeight: null,
        isMobileView: false,
        isMobileDevice: false,
        isOnline: false,
        error: null,
    },
    questionStore: {
        isCreatingProfile: false,
        isFetchingProfiles: false,
        isFetchingProfile: false,
        isFetchingProfileList: false,
        savingNewProfile: false,
        newProfileSaved: false,
        profileSavedMessage: '',
        profileErrorMessage: '',
        profiles: [],
        profileList: [],
        profilesErrorMessage: null,
        profileListErrorMessage: null,
        currentProfile: {
            name: '',
            qpid: 0,
            teamname: '',
            questions: [],
            isDeletingQuestion: false,
            isDeletingProfile: false,
            deleteQuestionSuccessMessage: '',
            deleteQuestionErrorMessage: '',
            questionId: null,
            createdQuestionErrorMessage: '',
            createdQuestionSuccessMessage: '',
            createdQuestion: false,
            profileDeletedMessage: null,
            profileDeleted: false,
            errorDeletingProfileMessage: null,
            isSavingQuestion: false,
            isCreatingQuestion: false,
        },
    },
    locales: {
        locale: lang || 'en-gb',
    },
    talentPool: {
        list: [],
        loading: false,
        hasMoreSubscribers: false,
        errors: {
            message: null,
        },
    },
    vacancyPool: {
        list: [],
        loading: false,
        hasMoreSubscribers: false,
        errors: {
            message: null,
        },
    },
    settings: {
        loading: false,
        updating: false,
    },
    subscription: {
        submittingSubscription: false,
        products: {
            addons: {},
            credits: {},
        },
        type: 'month',
        stage: '',
        items: [],
        proration: '',
        subTotal: 0,
        taxTotal: 0,
        startingBalance: 0,
        total: 0,
        amountDue: 0,
    },
    topUp: {
        products: [],
        loading: false,
    },
};
