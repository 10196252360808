import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.RECEIVED_SUBSCRIPTION_DETAILS:
                return {
                    ...draft,
                    ...payload,
                };

            case actions.SUBMITTING_SUBSCRIPTION:
                draft.submittingSubscription = payload.submittingSubscription;

                break;

            case actions.REVIEW_SUBSCRIPTION_CHANGES:
                draft.stage = payload.stage;
                draft.items = payload.items;
                draft.proration = payload.proration;
                draft.subTotal = payload.subtotal;
                draft.taxTotal = payload.total_tax_amounts.length
                    ? payload.total_tax_amounts[0].amount
                    : 0;
                draft.startingBalance = payload.starting_balance;
                draft.total = payload.total;
                draft.amountDue = payload.amount_due;

                break;

            case actions.CLEAR_SUBSCRIPTION_REVIEW:
                draft.stage = '';
                draft.items = [];
                draft.proration = '';
                draft.subTotal = 0;
                draft.taxTotal = 0;
                draft.startingBalance = 0;
                draft.total = 0;
                draft.amountDue = 0;

                break;

            case actions.SUBSCRIPTION_UPDATED:
                draft.stage = payload.stage;

                break;
        }
    });
