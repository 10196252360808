import React, { useState } from 'react';
import {
    Drawer,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    IconButton,
    styled,
} from '@mui/material';
import { ChevronLeftOutlined, ExpandMore } from '@mui/icons-material';
import { useIntl } from 'react-intl';

const StyledAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.secondary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',

    svg: {
        height: '30px',
        width: '100px',
    },
    img: {
        height: '30px',
        width: '100px',
    },
}));

function NavigationDrawer({ open, onClose, items, getBrandLogo }) {
    const { formatMessage } = useIntl();
    // const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <nav>
            <Drawer
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                variant="temporary"
                anchor="left"
                open={open}
                onClose={onClose}
                sx={{
                    display: { xs: 'block', lg: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '280px',
                        borderRadius: '0 !important',
                    },
                }}
            >
                <DrawerHeader>
                    {getBrandLogo()}
                    <IconButton sx={{ flexGrow: 0, width: '42px' }} onClick={onClose}>
                        <ChevronLeftOutlined sx={{ color: 'white' }} />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {items.map((accordion, index) => (
                    <StyledAccordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleAccordionChange(`panel${index}`)}
                        square
                        elevation={0}
                        disableGutters
                        sx={{
                            borderRadius: '0 !important',
                        }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            {formatMessage({ id: accordion.labelId })}
                        </AccordionSummary>
                        <AccordionDetails sx={{ bgcolor: 'grey.100', pb: 0 }}>
                            <List>
                                {accordion.menuItems.map((item) => (
                                    <ListItemButton key={item.id} onClick={item.onClick}>
                                        <ListItemText
                                            primary={formatMessage({ id: item.labelId })}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </AccordionDetails>
                    </StyledAccordion>
                ))}
            </Drawer>
        </nav>
    );
}

export default NavigationDrawer;
