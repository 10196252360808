import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.REGISTER_REQUEST:
                draft.errorMessage = '';
                draft.isRegistering = true;

                break;

            case actions.REGISTRATION_ERROR:
                draft.errorMessage = payload.errorMessage;
                draft.isRegistering = false;

                break;

            case actions.REGISTRATION_SUCCESS:
                draft.registrationMessage = payload.registrationMessage;
                draft.isRegistered = true;
                draft.isRegistering = false;

                break;
        }
    });
