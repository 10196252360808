export const formatPrice = (price) => {
    let newPrice = price;

    newPrice = parseFloat(Math.round(price * 100) / 100).toFixed(2);

    return `£${newPrice}`;
};

const buildInitials = (name = '') => {
    const nameArray = name.split(' ');

    if (!nameArray.length) return '';

    const firstInitial = nameArray[0].substring(0, 1).toUpperCase();

    if (nameArray.length > 1) {
        const secondInitial = nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();

        return firstInitial + secondInitial;
    }

    return firstInitial;
};

export function stringAvatar(name, sxProps = {}) {
    return {
        sx: {
            bgcolor: '#BCB3FF',
            color: '#030037',
            fontSize: 15,
            ...sxProps,
        },
        children: buildInitials(name),
    };
}
