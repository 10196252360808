import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup, Paper } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: 20,
        },
        '&:first-of-type': {
            borderRadius: 20,
        },
        '&.MuiToggleButton-root': {
            paddingTop: '3px',
            paddingBottom: '3px',
            textTransform: 'none',
        },
    },
}));

export default function QuestionTypeSwitch({ onChange, selected = 'professional' }) {
    const [type, setType] = React.useState(selected);
    const { formatMessage } = useIntl();

    const handleType = (event, newType) => {
        setType(newType);
        onChange(newType);
    };

    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: '50px !important',
                    flexWrap: 'wrap',
                }}
            >
                <StyledToggleButtonGroup
                    size="small"
                    color="primary"
                    value={type}
                    exclusive
                    onChange={handleType}
                    aria-label="text alignment"
                >
                    <ToggleButton value="professional" aria-label="professional">
                        {formatMessage({ id: 'ReferenceRequest.questionsPreview.professional' })}
                    </ToggleButton>
                    <ToggleButton value="personal" aria-label="personal">
                        {formatMessage({ id: 'ReferenceRequest.questionsPreview.personal' })}
                    </ToggleButton>
                    <ToggleButton value="academic" aria-label="academic">
                        {formatMessage({ id: 'ReferenceRequest.questionsPreview.academic' })}
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Paper>
        </div>
    );
}
