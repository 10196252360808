import { produce } from 'immer';
import * as actions from '../constants/action-types';

export default (state = {}, { type, payload }) =>
    produce(state, (draft) => {
        switch (type) {
            case actions.RECEIVED_TOP_UP_DETAILS:
                draft.products = payload.products;

                break;

            case actions.TOP_UP_PAYMENT:
                draft.loading = payload.loading;

                break;
        }
    });
